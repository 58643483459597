import { useContext } from 'react'
import DataTable from '../components/table/DataTable'
import { ContentContainer } from '../components/table/tableElements'
import { AppContext } from '../utils/context/AppContext'
import { Emphasized } from '../utils/elements/miscElements'
import { getFormattedNumber, getFormattedPrice } from '../utils/helper/Helper'
import useTranslate from '../utils/hooks/useTranslate'
import { getSpacerCol } from '../components/table/tableCols/tableCols'

const CreditsFaq = () => {
  const context = useContext(AppContext)
  const customer = context.completeDataSet.customer
  const priceData = getPriceData(customer.creditPrices, context.language)
  const priceCols = getPriceCols()

  return (
    <div className="legal">
      <Content {...{ priceData, priceCols }} />
    </div>
  )
}

export default CreditsFaq

const getPriceData = (creditPrices, lang) => {
  const defaultPrices = creditPrices.map((creditPrice) => ({
    amount: getFormattedNumber(creditPrice.count, lang),
    priceSinge: getFormattedPrice(creditPrice.price, lang),
    pricePackage: creditPrice.count > 1 ? getFormattedPrice(creditPrice.count * creditPrice.price, lang, 0) : null
  }))
  const customPrices = [
    {
      amount: getFormattedNumber(10_000, lang),
      priceSinge: null,
      pricePackage: 'uponRequest'
    }
  ]
  return [...defaultPrices, ...customPrices]
}

const getPriceCols = () => [
  {
    Header: 'amount',
    accessor: 'amount',
    textAlign: 'right',
    Cell: ({ value }) => (
      <div style={{ textAlign: 'right' }}>
        <span>{value}</span>
      </div>
    ),
    disableSortBy: true
  },
  {
    Header: 'pricePerCredit',
    accessor: 'priceSinge',
    textAlign: 'right',
    Cell: ({ value }) => (
      <div style={{ textAlign: 'right' }}>
        <span>{value}</span>
      </div>
    ),
    disableSortBy: true
  },
  {
    Header: 'packetPrice',
    accessor: 'pricePackage',
    textAlign: 'right',
    Cell: ({ value }) => {
      const t = useTranslate()
      return (
        <div style={{ textAlign: 'right' }}>
          <span>{t(value)}</span>
        </div>
      )
    },
    disableSortBy: true
  },
  getSpacerCol()
]

const Content = ({ priceData, priceCols }) => {
  const context = useContext(AppContext)
  const lang = context.language

  if (lang === 'en') return <ContentEnglish {...{ priceData, priceCols }} />
  return <ContentGerman {...{ priceData, priceCols }} />
}

const ContentGerman = ({ priceData, priceCols }) => (
  <ContentContainer style={{ maxWidth: '600px', margin: 0, padding: 0 }}>
    <div className="credit-infos">
      <h3 style={{ marginTop: 0 }}>Wofür benötigt man Credits?</h3>
      <p>
        Mit PERSEO Credits rechnen wir die Durchführung von Tests ab. Credits können für alle Tests und Zusatzmodule
        genutzt werden. Auch ein individuelles Test-Design kann mit Credits bezahlt werden. Der Vorteil: Sie sind
        flexibel und brauchen sich im Vorfeld nicht auf bestimmte Tests oder Berufsgruppen festzulegen.
      </p>
      <h3>Was kosten Credits?</h3>
      <div style={{ maxWidth: '290px' }}>
        <DataTable data={priceData} columns={priceCols} tableName="creditInfos" hideArchiveButton hideResultCount />
      </div>
      <h3>Muss ein bestimmtes Credit-Paket abgenommen werden?</h3>
      <p>Nein, Sie können auch die exakt benötigte Menge Credits abnehmen und erhalten den jeweiligen Mengenrabatt.</p>
      <h3>Verfallen Credits irgendwann?</h3>
      <p>
        Nein, Credits sind zeitlich unbegrenzt gültig und müssen nicht innerhalb eines bestimmten Zeitraum verbraucht
        werden. Bei Löschung des Kunden-Accounts können übrige Credits allerdings nicht zurückerstattet werden.
      </p>
      <h3>Wann werden Credits abgebucht?</h3>
      <p>
        Credits werden abgebucht, sobald ein Test durchgeführt wurde. Für abgebrochene und abgelaufene Tests werden
        keine Credits abgebucht. Auch beim Anlegen von Tests und Teilnehmern entstehen keine Kosten.
      </p>
      <h3>Welche Abrechnungsoptionen gibt es?</h3>
      <p>Credits können vorab oder nachträglich abgerechnet werden.</p>
      <p>
        <Emphasized>Vorab:</Emphasized> Sie laden Ihr Credit-Konto wann immer Sie möchten im Shop auf und führen mit dem
        vorhandenen Guthaben Tests durch. Diese Option ist sinnvoll, wenn Sie viel testen und sich Mengenrabatt sichern
        möchten.
      </p>
      <p style={{ margin: 0 }}>
        <Emphasized>Nachträglich:</Emphasized> Wenn Sie wenig testen oder nicht wissen, wie viele Tests Sie benötigen,
        können Sie unsere nachträgliche Abrechnung nutzen. Hierbei führen Sie Tests durch, ohne vorher ein Guthaben
        aufgeladen zu haben. Das dabei entstehende negative Guthaben wird jeweils zum Anfang eines Monats automatisch
        ausgeglichen, indem der ausstehende Betrag dem Kundenkonto gutgeschrieben und die entsprechende Anzahl an
        Credits in Rechnung gestellt wird.
      </p>
    </div>
  </ContentContainer>
)

const ContentEnglish = ({ priceData, priceCols }) => (
  <ContentContainer style={{ maxWidth: '600px', margin: 0, padding: 0 }}>
    <div className="credit-infos">
      <h3 style={{ marginTop: 0 }}>What are Credits used for?</h3>
      <p>
        With PERSEO Credits, we calculate the cost of conducting tests. Credits can be used for all tests and add-on
        modules. Even custom test designs can be paid for with credits. The advantage: they are flexible and you
        don&apos;t need to commit to specific tests groups in advance.
      </p>
      <h3>What is the cost of Credits?</h3>
      <div style={{ maxWidth: '320px' }}>
        <DataTable data={priceData} columns={priceCols} tableName="creditInfos" hideArchiveButton hideResultCount />
      </div>
      <h3>Is a specific Credit package required?</h3>
      <p>No, you can also purchase the exact amount of Credits you need and receive the respective volume discount.</p>
      <h3>Do Credits expire?</h3>
      <p>
        No, Credits are valid indefinitely and do not need to be used within a specific timeframe. However, in the event
        of customer account deletion, any remaining Credits cannot be refunded.
      </p>
      <h3>When are Credits deducted?</h3>
      <p>
        Credits are deducted as soon as a test has been conducted. No Credits are deducted for aborted and expired
        tests. Also, there are no costs associated with creating tests and participants.
      </p>
      <h3>What billing options are available?</h3>
      <p>Credits can be billed in advance or retrospectively.</p>
      <p>
        <Emphasized>In Advance:</Emphasized> You can top up your credit account in the shop whenever you want and
        conduct tests with the available balance. This option is useful if you conduct a lot of tests and want to secure
        volume discounts.
      </p>
      <p style={{ margin: 0 }}>
        <Emphasized>Retrospectively:</Emphasized> If you conduct fewer tests or are unsure about the quantity you need,
        you can use our retrospective billing. Here, you conduct tests without preloading a balance. The resulting
        negative balance is automatically reconciled at the beginning of each month. The outstanding amount is credited
        to the customer account, and the corresponding number of Credits is invoiced.
      </p>
    </div>
  </ContentContainer>
)
