import { setState } from '../../../utils/helper/Helper'
import useTranslate from '../../../utils/hooks/useTranslate'
import { PageHeadline } from '../../../utils/elements/miscElements'
import { detectEmailType } from '../sendEmailUtils'
import PreviewSelector from '../subComponents/PreviewSelector'
import PreviewMailSender from '../subComponents/PreviewMailSender'
import React, { useEffect } from 'react'
import { useMemo } from 'react'
import EmailHtmlEditor from '../subComponents/EmailHtmlEditor'
import styled from 'styled-components'
import { ScrollContainerForHtmlEditor } from './EmailTemplatePage'

const EmailPreviewPage = ({ data, setData }) => {
  const t = useTranslate()

  const template = useMemo(() => {
    return data.availableTemplates.find((t) => t.publicKey === data.selectedTemplateKey)
  }, [data.availableTemplates, data.selectedTemplateKey])

  useEffect(() => {
    const emailType = detectEmailType(data, template)
    setState(setData, 'emailType', emailType)
  }, [])

  return (
    <>
      <PageHeadline>{t('previewAndTestMail')}</PageHeadline>
      <OuterContainer className="email-preview-container">
        <ScrollContainerForHtmlEditor>
          <EmailHtmlEditor template={template} data={data} />
        </ScrollContainerForHtmlEditor>

        <ScrollContainerForHtmlEditor>
          <PreviewSelector {...{ data, setData }} />
          <PreviewMailSender {...{ data }} />
        </ScrollContainerForHtmlEditor>
      </OuterContainer>
    </>
  )
}

const OuterContainer = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: var(--space-7);
  overflow: hidden;
  padding-left: 5px;
  margin-left: -5px;
`
export default EmailPreviewPage
