import { useContext, useRef, useState } from 'react'
import ScrollContainer from '../components/scrollContainer/ScrollContainer'
import { ContentContainer } from '../components/table/tableElements'
import {
  ButtonPrimary,
  CheckboxElement,
  Label,
  OptionEntry,
  PageHeadline,
  WizardForm
} from '../utils/elements/miscElements'
import useTranslate from '../utils/hooks/useTranslate'
import styled from 'styled-components'
import { fetchData, setStatusBar } from '../utils/helper/Helper'
import { AppContext } from '../utils/context/AppContext'
import { STATUS_BAR_TYPES } from '../utils/constants/constants'

const Feedback = () => {
  const t = useTranslate()
  const context = useContext(AppContext)
  const [text, setText] = useState('')
  const [type, setType] = useState('misc')
  const [feedbackSent, setFeedbackSent] = useState(false)
  const [errorVisible, setErrorVisible] = useState(false)
  const inputRef = useRef(null)

  const feedbackTypes = [
    {
      type: 'misc',
      icon: 'icon-feedback-filled',
      label: 'general',
      textboxLabel: 'yourMessage'
    },
    {
      type: 'improvement',
      icon: 'icon-lightbulb',
      label: 'improvement',
      textboxLabel: 'whatCanWeImprove'
    },
    {
      type: 'bug',
      icon: 'icon-bug',
      label: 'bug',
      textboxLabel: 'pleaseDescribeError'
    }
  ]

  const selectedType = feedbackTypes.find((option) => option.type === type)

  const sendFeedback = async (e) => {
    e.preventDefault()
    if (text.length === 0) {
      setErrorVisible(true)
      if (inputRef.current) {
        inputRef.current.focus()
      }
      return
    }
    setFeedbackSent(true)
    const payload = {
      text: text,
      type: type
    }
    const status = await fetchData(payload, 'feedbacks', context)
    try {
      if (status) {
        setStatusBar({
          controller: context.statusBarController,
          type: STATUS_BAR_TYPES.success,
          text: 'feedbackSuccessfullySent',
          setVisible: true
        })
      }
    } catch (e) {
      console.error(e)
    }
  }

  const handleClick = (e, type) => {
    e.preventDefault()
    setType(type)
  }

  return (
    <ScrollContainer type="main">
      <ContentContainer style={{ maxWidth: '500px' }}>
        <PageHeadline>Feedback</PageHeadline>
        <div>
          <p>{t('feedbackHint')}</p>
          <WizardForm>
            <OptionEntry>
              <Label>Thema</Label>
              <FeedbackTypeContainer>
                {feedbackTypes.map((option) => {
                  const checked = type === option.type
                  return (
                    <li key={option.type}>
                      <CheckboxElement checked={checked} onClick={(e) => handleClick(e, option.type)}>
                        <Icon style={option.iconStyle} className={`svg-icon ${option.icon}`} />
                        <span>{t(option.label)}</span>
                      </CheckboxElement>
                    </li>
                  )
                })}
              </FeedbackTypeContainer>
            </OptionEntry>
            <OptionEntry>
              <Label>{t(selectedType.textboxLabel)}</Label>
              <textarea
                ref={inputRef}
                className={errorVisible && text.length === 0 ? 'has-error' : ''}
                value={text}
                rows={5}
                onChange={(e) => {
                  setText(e.target.value)
                  setErrorVisible(false)
                }}
                disabled={feedbackSent}
              />
            </OptionEntry>
            <ButtonPrimary onClick={sendFeedback} disabled={feedbackSent} style={{ width: '100px' }} content="send" />
          </WizardForm>
        </div>
      </ContentContainer>
    </ScrollContainer>
  )
}

export default Feedback

const FeedbackTypeContainer = styled.ul`
  list-style-type: none;
  padding: 0;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  @media (max-width: 600px) {
    grid-template-columns: unset;
  }
  gap: var(--space-3);
  button {
    width: 100%;
    gap: var(--space-5);
    padding: var(--space-5);
    justify-items: center;
    @media (max-width: 600px) {
      grid-template-columns: auto 1fr;
      align-items: center;
      justify-items: flex-start;
      padding: var(--space-4);
    }
  }
`
const Icon = styled.span`
  width: var(--space-6);
  height: var(--space-6);
`
