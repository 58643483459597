import { useContext, useEffect } from 'react'
import { AppContext } from '../../utils/context/AppContext'
import { setState } from '../../utils/helper/Helper'

const useTemplateUpdates = (setData, defaultTemplates, reminder = null) => {
  const { completeDataSet } = useContext(AppContext)
  useEffect(() => {
    const availableTemplates = reminder
      ? [...defaultTemplates, ...completeDataSet.ptInvitationTemplates, reminder.template]
      : [...defaultTemplates, ...completeDataSet.ptInvitationTemplates]
    setState(setData, 'availableTemplates', availableTemplates)
  }, [completeDataSet.ptInvitationTemplates])
}

export default useTemplateUpdates
