import { CREDIT_PRICES } from '../utils/constants/constants'

export class Customer {
  constructor({ ...props }) {
    this.entity = 'Customers'
    this.anonymizeTimeSpan = props.anonymizeTimeSpan
    this.archiveTimeSpan = props.archiveTimeSpan
    this.customerCity = props.customerCity || ''
    this.customerCountry = props.customerCountry || ''
    this.customerCreditBalance = props.customerCreditBalance
    this.customerName = props.customerName || ''
    this.customerStreet = props.customerStreet || ''
    this.customerZipCode = props.customerZipCode || ''
    this.discountRate = props.discountRate || 0
    this.infoHtml = this.getInfoHtml()
    this.creditPrices = this.getCreditPrices(props)
  }
  getInfoHtml() {
    return this.customerName + '<br>' + this.customerStreet + '<br>' + this.customerZipCode + ' ' + this.customerCity
  }
  getCreditPrices(props) {
    if (props.hasCreditPrice1Euro) {
      return CREDIT_PRICES.prices_2023.map((option) => ({
        ...option,
        price: 1
      }))
    }
    return props.using2022PriceList ? CREDIT_PRICES.prices_2022 : CREDIT_PRICES.prices_2023
  }
}
