import ScrollContainer from '../../../components/scrollContainer/ScrollContainer'
import { TileContainer } from '../../../components/table/tableElements'
import AddToTestCheckInputTile from '../../../components/tiles/AddToTestCheckInputTile'
import BillingAddressTile from '../../../components/tiles/BillingAddressTile'
import BillingHintTile from '../../../components/tiles/BillingHintTile'
import SelectedParticipantsTile from '../../../components/tiles/SelectedParticipantsTile'
import { PageHeadline } from '../../../utils/elements/miscElements'
import useTranslate from '../../../utils/hooks/useTranslate'

const AddToTestCheckInputPage = ({ data, selectedParticipants, maximumCreditSum }) => {
  const t = useTranslate()
  const showBillingTiles = maximumCreditSum > 0

  return (
    <>
      <PageHeadline>{t('checkInputs')}</PageHeadline>
      <ScrollContainer>
        <TileContainer style={{ padding: 0 }}>
          <AddToTestCheckInputTile
            {...{
              data,
              selectedParticipants,
              maximumCreditSum
            }}
          />
          <SelectedParticipantsTile {...{ selectedParticipants }} />
          {showBillingTiles > 0 && (
            <>
              <BillingAddressTile />
              <BillingHintTile />
            </>
          )}
        </TileContainer>
      </ScrollContainer>
    </>
  )
}

export default AddToTestCheckInputPage
