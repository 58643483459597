import React from 'react'
import { useEffect, useState, useContext } from 'react'
import { useLocation } from 'react-router-dom'
import PageGroup from '../../components/pageGroup/PageGroup'
import { getMaximumCreditSum, setState, setStatusBar } from '../../utils/helper/Helper'
import AddPtSuccessPage from '../sharedWizardElements/addPtSuccessPage'
import { AppContext } from '../../utils/context/AppContext'
import { ButtonPrimary, ButtonSecondary } from '../../utils/elements/miscElements'
import { SUCCESS_PAGE_TYPES, STATUS_BAR_TYPES } from '../../utils/constants/constants'
import SelectProcessAndAssessmentsPage from '../../pages/selectProcessAndAssessmentsPage'
import { PAGES } from '../../utils/constants/pages'
import GenerateTans from './subPages/GenerateTans'
import CheckInput from './subPages/CheckInput'
import { addParticipants, updateStateFromUrlParams } from './addParticipantUtils'
import DataSource from './subPages/DataSource'
import UploadParticipants from './subPages/UploadParticipants'
import { ButtonAfterPtAdd, ButtonDownloadAccessData } from '../sharedWizardElements/addPtWizardButtons'
import useTranslate from '../../utils/hooks/useTranslate'
import useRedirect from '../../utils/hooks/useRedirect'
import { validateBillingData } from '../../pages/shop/shopUtils'

const AddParticipantWizard = () => {
  const t = useTranslate()
  const redirect = useRedirect()
  const location = useLocation()
  const context = useContext(AppContext)
  const completeDataSet = context.completeDataSet
  const assessments = completeDataSet.assessments

  const [data, setData] = useState({
    processUuid: null,
    assessmentUuids: [],
    pAnon: false,
    participantCount: 0,
    participantList: [],
    uploadedData: [],
    useFileUpload: false,
    fileName: '',
    parsedCsvData: [],
    uploadedHeaders: [],
    customFields: []
  })

  const [activePageId, setActivePageId] = useState(0)
  const [newData, setNewData] = useState({})
  const [isLoading, setIsLoading] = useState(false)

  const optionDescriptions = {
    process: t('selectProcessToAddParticipants'),
    assessments: t('selectAtLeastOneTest')
  }

  const { billingDataValid } = validateBillingData(context.completeDataSet.billing)
  const maximumCreditSum = getMaximumCreditSum(data.assessmentUuids, context, data.participantCount)

  useEffect(() => {
    updateStateFromUrlParams(location, setData, setActivePageId)
  }, [location])

  useEffect(() => {
    if (data.useFileUpload) {
      setState(setData, 'participantCount', data.participantList.length)
    }
  }, [data.useFileUpload])

  const pages = {
    selectProcessAndAssessments: {
      content: (
        <SelectProcessAndAssessmentsPage data={data} setData={setData} optionDescriptions={optionDescriptions} />
      ),
      title: t('processesAndTests'),
      buttonSecondary: <ButtonSecondary content="cancel" onClick={() => redirect(PAGES.participants)} />,
      buttonPrimary: (
        <ButtonPrimary
          content="next"
          onClick={() => setActivePageId(activePageId + 1)}
          disabled={data.assessmentUuids.length === 0}
        />
      )
    },
    dataSource: {
      content: <DataSource data={data} setData={setData} />,
      title: t('dataSource'),
      buttonSecondary: <ButtonSecondary content="back" onClick={() => setActivePageId(activePageId - 1)} />,
      buttonPrimary: <ButtonPrimary content="next" onClick={() => setActivePageId(activePageId + 1)} />
    },
    uploadParticipants: {
      content: <UploadParticipants {...{ data, setData }} />,
      title: <span style={{ textTransform: 'capitalize' }}>{t('participantList')}</span>,
      buttonSecondary: <ButtonSecondary content="back" onClick={() => setActivePageId(activePageId - 1)} />,
      buttonPrimary: (
        <ButtonPrimary
          content="next"
          onClick={() => setActivePageId(activePageId + 1)}
          disabled={data.parsedCsvData.length === 0}
        />
      )
    },
    generateTans: {
      content: <GenerateTans data={data} setData={setData} />,
      title: t('participantsAndPrivacy'),
      buttonSecondary: <ButtonSecondary content="back" onClick={() => setActivePageId(activePageId - 1)} />,
      buttonPrimary: (
        <ButtonPrimary
          content="next"
          onClick={() => setActivePageId(activePageId + 1)}
          disabled={data.participantCount === 0}
        />
      )
    },
    checkInput: {
      content: <CheckInput data={data} assessments={assessments} />,
      title: t('checkInputs'),
      buttonSecondary: <ButtonSecondary content="back" onClick={() => setActivePageId(activePageId - 1)} />,
      buttonPrimary: (
        <ButtonPrimary
          disabled={(maximumCreditSum > 0 && !billingDataValid) || isLoading}
          content="add"
          onClick={() => {
            setIsLoading(true)
            addParticipants(data, context).then((newData) => {
              if (newData) {
                setNewData(newData)
                setActivePageId(activePageId + 1)
                setStatusBar({
                  controller: context.statusBarController,
                  type: STATUS_BAR_TYPES.success,
                  text: 'participantCreated'
                })
              }
            })
          }}
        />
      )
    },
    successPage: {
      content: <AddPtSuccessPage newData={newData} type={SUCCESS_PAGE_TYPES.newParticipants} />,
      hidePageIndicator: true,
      largeButtons: true,
      buttonSecondary: <ButtonDownloadAccessData {...{ newData }} />,
      buttonPrimary: <ButtonAfterPtAdd {...{ newData, data }} />
    }
  }

  return (
    <PageGroup
      pages={[
        pages.selectProcessAndAssessments,
        pages.dataSource,
        data.useFileUpload ? pages.uploadParticipants : pages.generateTans,
        pages.checkInput,
        pages.successPage
      ]}
      activePageId={activePageId}
    />
  )
}

export default AddParticipantWizard
