import { ButtonPrimary, ButtonSecondary, Label, selectStyles } from '../../../utils/elements/miscElements'
import Select from 'react-select'
import CustomColorPicker from './CustomColorPicker'
import useTranslate from '../../../utils/hooks/useTranslate'
import { fetchData, getOptionByValue, setState, setStatusBar, updateEntities } from '../../../utils/helper/Helper'
import { useContext } from 'react'
import { AppContext } from '../../../utils/context/AppContext'
import styled from 'styled-components'
import { useEffect } from 'react'
import SaveTemplateModal from './SaveTemplateModal'
import { MODAL_TYPES, STATUS_BAR_TYPES } from '../../../utils/constants/constants'
import { toggleMediaLibrary } from './MediaLibrary'

const EmailEditorSidebar = ({ data, setData, template, updateFn }) => {
  const t = useTranslate()
  const context = useContext(AppContext)

  const updateLogoWidth = (val) => {
    if (val.length > 3) {
      return
    }
    updateFn('logoWidthPx', val)
  }

  return (
    <SidebarContainer>
      {data.showTemplateManager && <TemplateManager {...{ data, setData, template }} />}
      <ControlsContainer className="email-editor-controls">
        <LabelContainer>
          <Label>{t('headerColor')}</Label>
          <CustomColorPicker template={template} fieldName="headerColor" updateFn={updateFn} />
        </LabelContainer>

        <Row>
          <LabelContainer className="email-editor-logo-container">
            <Label>{t('Logo')}</Label>
            <LogoContainer
              onClick={(e) => {
                e.preventDefault()
                toggleMediaLibrary(context, template, updateFn)
              }}>
              <img src={template.logoPath} />
            </LogoContainer>
          </LabelContainer>
          <LabelContainer style={{ display: 'grid', alignContent: 'flex-start', position: 'relative' }}>
            <Label>{t('logoWidth')}</Label>
            <div style={{ position: 'relative' }}>
              <input
                type="text"
                value={template.logoWidthPx}
                style={{ width: '64px' }}
                onChange={(e) => updateLogoWidth(e.target.value)}
              />
              <UnitLabel>px</UnitLabel>
            </div>
          </LabelContainer>
        </Row>
        <Row>
          <LabelContainer>
            <Label>{t('footerColor')}</Label>
            <CustomColorPicker template={template} fieldName="footerBgColor" updateFn={updateFn} />
          </LabelContainer>
          <LabelContainer>
            <Label>{t('footerTextColor')}</Label>
            <CustomColorPicker template={template} fieldName="footerTextColor" updateFn={updateFn} />
          </LabelContainer>
        </Row>
      </ControlsContainer>
      {data.showTemplateManager && <SaveTemplateButton {...{ data, setData }} />}
    </SidebarContainer>
  )
}

export default EmailEditorSidebar

const SidebarContainer = styled.div`
  display: grid;
  grid-gap: var(--space-5);
  align-items: baseline;
  align-content: baseline;
`

const SaveTemplateButton = ({ data, setData }) => {
  const context = useContext(AppContext)
  const buttonStyles = {
    flexGrow: 0,
    minHeight: '32px',
    minWidth: '80px',
    fontSize: 'var(--fs-2)',
    width: 'auto',
    marginLeft: 'auto'
  }

  return (
    <div style={{ marginTop: 'var(--space-3)' }}>
      <ButtonSecondary
        style={buttonStyles}
        content={'saveAsTemplate'}
        onClick={(e) => openSaveTemplateModal(data, setData, context, e)}
      />
    </div>
  )
}

const TemplateManager = ({ data, setData, template }) => {
  const options = getOptions(data)
  const context = useContext(AppContext)
  const t = useTranslate()

  useEffect(() => {
    updateModalProps(context, data, setData)
  }, [data.saveMethod, data.newTemplateName])

  return (
    <LabelContainer>
      <Label>{t('template')}</Label>
      <Select
        options={options}
        onChange={(selectedOption) => setState(setData, 'selectedTemplateKey', selectedOption.value)}
        value={getOptionByValue(options, template.publicKey)}
        styles={selectStyles}
        isSearchable={false}
      />
    </LabelContainer>
  )
}

const UnitLabel = styled.span`
  position: absolute;
  top: 10px;
  left: 38px;
  color: var(--text-color-secondary);
`

const LabelContainer = styled.div`
  display: grid;
  gap: var(--space-1);
`

const Row = styled.div`
  display: flex;
  gap: var(--space-4);
  flex-wrap: wrap;
`

const LogoContainer = styled.button`
  background-color: #fff;
  border: 1px solid var(--border-grey);
  height: 128px;
  width: 128px;
  padding: var(--space-4);
  border-radius: var(--bdr-2);
  cursor: pointer;
  display: flex;
  align-items: center;
  img {
    width: 100%;
    height: auto;
  }
`

const ControlsContainer = styled.div`
  display: grid;
  gap: var(--space-3);
`

const openSaveTemplateModal = (data, setData, context, e) => {
  e.preventDefault()
  setState(setData, 'saveMethod', 'add')
  updateModalProps(context, data, setData)
  context.setModalOpen(true)
}

const getOptions = (data) => {
  return data.availableTemplates.map((template) => {
    const dirty = template.isDirty
    return {
      label: <TemplateName name={template.name} dirty={dirty} />,
      value: template.publicKey
    }
  })
}

const TemplateName = ({ name, dirty }) => {
  const t = useTranslate()
  return (
    <>
      <span>{name}</span>
      {dirty && <span style={{ color: 'var(--text-color-secondary)' }}> ({t('changed')})</span>}
    </>
  )
}

const updateModalProps = (context, data, setData) => {
  const nameEmpty = data.newTemplateName.length === 0
  context.setModalProps({
    headline: 'saveTemplate',
    content: <SaveTemplateModal {...{ data, setData }} />,
    buttonPrimary: (
      <ButtonPrimary
        disabled={nameEmpty}
        onClick={(e) => saveTemplate(data, setData, context, e)}
        modalButton
        content="save"
      />
    ),
    icon: 'icon-email modal',
    type: MODAL_TYPES.alert
  })
}

const saveTemplate = async (data, setData, context, e) => {
  e.preventDefault()
  context.setModalOpen(false)
  const template = data.availableTemplates.find((t) => t.publicKey === data.selectedTemplateKey)

  const payload = {
    title: data.newTemplateName,
    public_key: data.saveMethod === 'edit' ? data.selectedTemplateKey : null,
    template_data: template
  }

  const responseData = await fetchData(payload, 'save_invitation_template', context, 'dataChangeFailed', false)

  try {
    updateEntities(responseData.response.data, context)

    setData((prev) => ({
      ...prev,
      selectedTemplateKey: responseData.response.data.ptInvitationTemplates[0].publicKey,
      isDirty: false
    }))
    setStatusBar({
      controller: context.statusBarController,
      type: STATUS_BAR_TYPES.success,
      text: 'templateSavedSuccessfully',
      setVisible: true
    })
  } catch (e) {
    console.error(e)
  }
}
