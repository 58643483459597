import { CheckboxElement, CustomCheckmark, HoverConnector } from '../../utils/elements/miscElements'
import useTranslate from '../../utils/hooks/useTranslate'
import ScrollContainer from '../scrollContainer/ScrollContainer'
import { API_DATA, SELECT_OPTIONS } from '../../utils/constants/constants'
import { useContext } from 'react'
import { AppContext } from '../../utils/context/AppContext'
import { SettingsContainer } from './sharedElements/sharedElements'
import {
  Description,
  Title,
  InnerContainer,
  Icon
} from '../../wizards/addParticipantWizard/subComponents/SelectElements'
import styled from 'styled-components'
import { fetchData } from '../../utils/helper/Helper'
import { PAGES } from '../../utils/constants/pages'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'

const DisplayAndLanguageComponent = () => {
  const t = useTranslate()
  return (
    <SettingsContainer className="display-language-settings">
      <ScrollContainer type="settings" style={{ maxWidth: '500px', paddingBottom: 'var(--space-7)' }}>
        <h2>{t('displayAndLanguage')}</h2>
        <DisplayModeSelection />
        <LanguageSelection />
      </ScrollContainer>
    </SettingsContainer>
  )
}

export default DisplayAndLanguageComponent

const saveSettings = async (displayMode, language, context) => {
  const payload = {
    displayMode: displayMode,
    language: language
  }

  try {
    fetchData(payload, API_DATA.contactSettings.endPointEdit, context, 'settingsNotSaved', false)
  } catch (e) {
    console.error(e)
  }
}

const OptionsContainer = styled.div.attrs({
  className: 'options-container'
})`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: var(--space-3);
  .checkbox-element {
    gap: var(--space-3);
    padding: var(--space-6);
    justify-items: center;
    width: 100%;
  }
`

const LanguageSelection = () => {
  const t = useTranslate()
  const languages = t(SELECT_OPTIONS.languages)
  const context = useContext(AppContext)
  const history = useHistory()

  const handleLanguageChange = (newLang) => {
    context.setLanguage(newLang)
    saveSettings(context.displayMode, newLang, context)

    // Update the URL to the new path without triggering a full redirect
    const newPath = PAGES.displayAndLanguageSettings.urlSlugs[newLang]
    history.replace(newPath)
  }

  return (
    <>
      <h3>{t('language')}</h3>
      <p>{t('languageSelectExplanation')}</p>
      <OptionsContainer>
        {languages.map((lang) => {
          const checked = context.language === lang.value
          const handleClick = () => {
            if (!checked) {
              handleLanguageChange(lang.value)
            }
          }

          return (
            <HoverConnector key={lang.label}>
              <InnerContainer>
                <CheckboxElement checked={checked} onClick={handleClick} className="language-select-checkbox">
                  <Title style={{ textTransform: 'uppercase' }}>{lang.value}</Title>
                  <Description>{lang.label}</Description>
                </CheckboxElement>
                <CustomCheckmark
                  style={{ marginTop: 'var(--space-4)' }}
                  type="radio"
                  onClick={handleClick}
                  checked={checked}
                />
              </InnerContainer>
            </HoverConnector>
          )
        })}
      </OptionsContainer>
    </>
  )
}

const DisplayModeSelection = () => {
  const t = useTranslate()
  const displayModes = SELECT_OPTIONS.displayModes
  const context = useContext(AppContext)

  const handleDisplayModeChange = (mode) => {
    context.setDisplayMode(mode.value)
    setSlowCSSTransition()
  }

  return (
    <>
      <h3>{t('displayMode')}</h3>
      <OptionsContainer>
        {displayModes.map((mode) => {
          const checked = context.displayMode === mode.value
          const handleClick = () => {
            if (checked) return
            handleDisplayModeChange(mode)
            saveSettings(mode.value, context.language, context)
          }
          return (
            <HoverConnector key={mode.label}>
              <InnerContainer>
                <CheckboxElement checked={checked} onClick={handleClick}>
                  <Icon className={`svg-icon ${mode.icon}`}></Icon>
                  <Description>{t(mode.label)}</Description>
                </CheckboxElement>
                <CustomCheckmark
                  style={{ marginTop: 'var(--space-4)' }}
                  type="radio"
                  onClick={handleClick}
                  checked={checked}
                />
              </InnerContainer>
            </HoverConnector>
          )
        })}
      </OptionsContainer>
    </>
  )
}

export const setSlowCSSTransition = () => {
  document.body.classList.add('light-dark-transition')
  setTimeout(function () {
    document.body.classList.remove('light-dark-transition')
  }, 500)
}
