import BillingDataForm from '../../pages/shop/subcomponents/BillingDataForm'
import { ButtonPrimary, Modalfooter } from '../../utils/elements/miscElements'
import { useState, useContext, useRef } from 'react'
import ScrollContainer from '../scrollContainer/ScrollContainer'
import { AppContext } from '../../utils/context/AppContext'
import { saveBillingFormData, validateBillingData } from '../../pages/shop/shopUtils'

const BillingDataSettingsComponent = () => {
  const submitButtonRef = useRef(null)
  const context = useContext(AppContext)
  const { billing } = context.completeDataSet
  const [formData, setFormData] = useState({ ...billing, visibleErrors: [] })
  const { billingDataValid } = validateBillingData(formData)

  const content = (
    <ScrollContainer type="settings">
      <BillingDataForm formData={formData} setFormData={setFormData} />
      <Modalfooter type="settings">
        <ButtonPrimary
          ref={submitButtonRef}
          disabled={!billingDataValid}
          content="save"
          onClick={() =>
            saveBillingFormData({
              data: formData,
              context: context
            })
          }
        />
      </Modalfooter>
    </ScrollContainer>
  )

  return content
}

export default BillingDataSettingsComponent
