import CsvDownload from '../csvDownload/CsvDownload'
import React, { useRef } from 'react'
import { BulkActionButton, BulkActionButtonContent, BulkActionIcon } from './BulkEditStyles'
import {
  BULK_ACTIONS,
  CUSTOMER_VISIBILITY_STATUS,
  ENTITIES,
  MODAL_TYPES,
  STATUS_BAR_TYPES
} from '../../utils/constants/constants'
import { ButtonPrimary } from '../../utils/elements/miscElements'
import { applyBulkEdit, getRowDataForBulk } from './BulkUtils'
import { bulkDownloadResultPdfZip, isPt, isParticipant, updateEntities, setStatusBar } from '../../utils/helper/Helper'
import { PAGES } from '../../utils/constants/pages'
import { AlertModalText, getAlertModalHeadline, getPrimaryButtonText } from '../modal/modalUtils'
import useTranslate from '../../utils/hooks/useTranslate'
import { sendEmails } from '../../wizards/sendEmailWizard/sendEmailUtils'

const getActionButtons = (actions, redirect, rowData, context, entity, toggleAllRowsSelected, visibleColumns, t) => {
  const allActionButtons = []

  if (actions?.includes(BULK_ACTIONS.downloadSelectionAsCsv)) {
    allActionButtons.push(
      <CsvActionButton key="csvDownload" {...{ rowData, entity, toggleAllRowsSelected, visibleColumns }} />
    )
  }

  if (actions?.includes(BULK_ACTIONS.downloadSelectionAsPdf)) {
    const ptNumbers = getPtNumbers(rowData, entity)
    const pNrs = rowData.map((row) => row?.pNr)
    const keysForBulk = isPt(entity) ? ptNumbers : pNrs
    allActionButtons.push(
      <BulkActionButton
        key="downloadSelectionAsPdf"
        onClick={() => bulkDownloadResultPdfZip(keysForBulk, entity, context)}>
        <BulkActionButtonContent>
          <BulkActionIcon className="svg-icon icon-pdf" />
          <span>{t('downloadReports')}</span>
        </BulkActionButtonContent>
      </BulkActionButton>
    )
  }

  if (actions?.includes(BULK_ACTIONS.addToOtherTest)) {
    const participantList = rowData.map((p) => ({
      id: 'pid',
      value: p.pNr
    }))

    allActionButtons.push(
      <BulkActionButton key="addexisting" onClick={() => redirect(PAGES.addExisting, participantList)}>
        <BulkActionButtonContent>
          <BulkActionIcon className="svg-icon icon-add-test" />
          <span>{t('addParticipantsToTests')}</span>
        </BulkActionButtonContent>
      </BulkActionButton>
    )
  }

  if (actions?.includes(BULK_ACTIONS.sendEmail)) {
    const ptNumbers = getPtNumbers(rowData, entity)
    const urlParams = [{ id: 'ptNumber', value: ptNumbers }]

    allActionButtons.push(
      <BulkActionButton key="sendEmail" onClick={() => redirect(PAGES.sendEmails, urlParams)}>
        <BulkActionButtonContent>
          <BulkActionIcon className="svg-icon icon-email" />
          <span>{t('sendMail')}</span>
        </BulkActionButtonContent>
      </BulkActionButton>
    )
  }

  if (actions?.includes(BULK_ACTIONS.setCapabilities)) {
    const processList = rowData.map((process) => ({
      id: 'processUuid',
      value: process.processUuid
    }))

    allActionButtons.push(
      <BulkActionButton key="changecapabilities" onClick={() => redirect(PAGES.changeCapabilities, processList)}>
        <BulkActionButtonContent>
          <BulkActionIcon className="svg-icon icon-key" />
          <span>{t('changeCapabilities')}</span>
        </BulkActionButtonContent>
      </BulkActionButton>
    )
  }

  if (actions?.includes(BULK_ACTIONS.startTimer)) {
    const rowDataForBulk = getRowDataForBulk(entity, rowData)

    const bulkStartTimerParams = [
      [{ name: 'ptIsInvited', value: true }],
      'endPointEdit',
      ENTITIES.pts,
      rowDataForBulk,
      context
    ]

    const modalButton = (
      <ButtonPrimary
        modalButton
        content="startTimer"
        onClick={() => {
          context.setModalOpen(false)
          toggleAllRowsSelected(false)
          applyBulkEdit(...bulkStartTimerParams)
        }}
      />
    )

    allActionButtons.push(
      <BulkActionButton
        key="startTimer"
        onClick={() => {
          context.setModalProps({
            headline: 'startTimer',
            content: 'confirmStartTimer',
            buttonPrimary: modalButton,
            icon: 'icon-clock modal',
            type: MODAL_TYPES.alert
          })
          context.setModalOpen(true)
        }}>
        <BulkActionButtonContent>
          <BulkActionIcon className="svg-icon icon-clock" />
          <span>{t('startTimer')}</span>
        </BulkActionButtonContent>
      </BulkActionButton>
    )
  }

  if (actions?.includes(BULK_ACTIONS.resetTimer)) {
    const rowDataForBulk = getRowDataForBulk(entity, rowData)

    const bulkStartTimerParams = [
      [{ name: 'ptIsInvited', value: false }],
      'endPointEdit',
      ENTITIES.pts,
      rowDataForBulk,
      context
    ]

    const modalButton = (
      <ButtonPrimary
        modalButton
        content="resetTimer"
        onClick={() => {
          context.setModalOpen(false)
          toggleAllRowsSelected(false)
          applyBulkEdit(...bulkStartTimerParams)
        }}
      />
    )

    allActionButtons.push(
      <BulkActionButton
        key="resetTimer"
        onClick={() => {
          context.setModalProps({
            headline: 'resetTimer',
            content: 'confirmResetTimer',
            buttonPrimary: modalButton,
            icon: 'icon-clock-reverse modal',
            type: MODAL_TYPES.alert
          })
          context.setModalOpen(true)
        }}>
        <BulkActionButtonContent>
          <BulkActionIcon style={{ transform: 'translateX(-2px)' }} className="svg-icon icon-clock-reverse" />
          <span>{t('resetTimer')}</span>
        </BulkActionButtonContent>
      </BulkActionButton>
    )
  }

  if (actions?.includes(BULK_ACTIONS.unarchive)) {
    const modalHeadline = getAlertModalHeadline(entity, rowData.length, BULK_ACTIONS.unarchive, t)
    const primaryButtonText = getPrimaryButtonText(BULK_ACTIONS.unarchive)
    const modalText = <AlertModalText entity={entity} count={rowData.length} action={BULK_ACTIONS.unarchive} />

    const bulkRestoreParams = [
      [{ name: 'visibility', value: CUSTOMER_VISIBILITY_STATUS.visible }],
      'endPointRestore',
      entity,
      rowData,
      context
    ]

    const modalButton = (
      <ButtonPrimary
        modalButton
        content={primaryButtonText}
        onClick={() => {
          context.setModalOpen(false)
          toggleAllRowsSelected(false)
          applyBulkEdit(...bulkRestoreParams)
        }}
      />
    )

    allActionButtons.push(
      <BulkActionButton
        key="unarchive"
        onClick={() => {
          context.setModalProps({
            headline: modalHeadline,
            content: modalText,
            buttonPrimary: modalButton,
            icon: 'icon-unarchive modal',
            type: MODAL_TYPES.alert
          })
          context.setModalOpen(true)
        }}>
        <BulkActionButtonContent>
          <BulkActionIcon className="svg-icon icon-unarchive" />
          <span>{t('unarchive')}</span>
        </BulkActionButtonContent>
      </BulkActionButton>
    )
  }

  if (actions?.includes(BULK_ACTIONS.activate)) {
    const modalHeadline = getAlertModalHeadline(entity, rowData.length, BULK_ACTIONS.activate, t)
    const primaryButtonText = getPrimaryButtonText(BULK_ACTIONS.activate)
    const modalText = <AlertModalText entity={entity} count={rowData.length} action={BULK_ACTIONS.activate} />

    const bulkActivateParams = [
      [{ name: 'ptDeactivated', value: false }],
      'endPointEdit',
      ENTITIES.pts,
      rowData,
      context
    ]

    const modalButton = (
      <ButtonPrimary
        modalButton
        content={primaryButtonText}
        onClick={() => {
          context.setModalOpen(false)
          toggleAllRowsSelected(false)
          applyBulkEdit(...bulkActivateParams)
        }}
      />
    )

    allActionButtons.push(
      <BulkActionButton
        key="activate"
        onClick={() => {
          context.setModalProps({
            headline: modalHeadline,
            content: modalText,
            buttonPrimary: modalButton,
            icon: 'icon-activate modal',
            type: MODAL_TYPES.alert
          })
          context.setModalOpen(true)
        }}>
        <BulkActionButtonContent>
          <BulkActionIcon className="svg-icon icon-activate" />
          <span>{t('activate')}</span>
        </BulkActionButtonContent>
      </BulkActionButton>
    )
  }

  if (actions?.includes(BULK_ACTIONS.resendEmail)) {
    const modalHeadline = getAlertModalHeadline(entity, rowData.length, BULK_ACTIONS.resendEmail, t)
    const primaryButtonText = getPrimaryButtonText(BULK_ACTIONS.resendEmail)
    const modalText = <AlertModalText entity={entity} count={rowData.length} action={BULK_ACTIONS.resendEmail} />

    const modalButton = (
      <ButtonPrimary
        content={primaryButtonText}
        modalButton
        onClick={async () => {
          context.setModalOpen(false)
          toggleAllRowsSelected(false)
          setStatusBar({
            controller: context.statusBarController,
            type: STATUS_BAR_TYPES.loading,
            setVisible: true,
            text: 'emailsBeingSent'
          })

          const responseData = await sendEmails({ context: context, data: rowData, t: t })
          try {
            updateEntities({ participantMailLogs: responseData.response.data.participantMailLogs }, context)
            setStatusBar({
              controller: context.statusBarController,
              type: STATUS_BAR_TYPES.success,
              setVisible: true,
              text: 'emailsSent'
            })
          } catch (e) {
            console.error(e)
          }
        }}
      />
    )

    allActionButtons.push(
      <BulkActionButton
        key="resendMail"
        onClick={() => {
          context.setModalProps({
            headline: modalHeadline,
            content: modalText,
            buttonPrimary: modalButton,
            icon: 'icon-send modal',
            type: MODAL_TYPES.alert
          })
          context.setModalOpen(true)
        }}>
        <BulkActionButtonContent>
          <BulkActionIcon className="svg-icon icon-send" />
          <span>{t('sendAgain')}</span>
        </BulkActionButtonContent>
      </BulkActionButton>
    )
  }

  if (actions?.includes(BULK_ACTIONS.archive)) {
    const modalHeadline = getAlertModalHeadline(entity, rowData.length, BULK_ACTIONS.archive, t)
    const primaryButtonText = getPrimaryButtonText(BULK_ACTIONS.archive)
    const modalText = <AlertModalText entity={entity} count={rowData.length} action={BULK_ACTIONS.archive} />

    const bulkArchiveParams = [
      [{ name: 'visibility', value: CUSTOMER_VISIBILITY_STATUS.archived }],
      'endPointArchiveDelete',
      entity,
      rowData,
      context
    ]

    const modalButton = (
      <ButtonPrimary
        modalButton
        content={primaryButtonText}
        onClick={() => {
          context.setModalOpen(false)
          toggleAllRowsSelected(false)
          applyBulkEdit(...bulkArchiveParams)
        }}
      />
    )

    allActionButtons.push(
      <BulkActionButton
        key="archive"
        onClick={() => {
          context.setModalProps({
            headline: modalHeadline,
            content: modalText,
            buttonPrimary: modalButton,
            icon: 'icon-archive modal',
            type: MODAL_TYPES.alert
          })
          context.setModalOpen(true)
        }}>
        <BulkActionButtonContent>
          <BulkActionIcon className="svg-icon icon-archive" />
          <span>{t('putInArchive')}</span>
        </BulkActionButtonContent>
      </BulkActionButton>
    )
  }

  if (actions?.includes(BULK_ACTIONS.deactivate)) {
    const modalHeadline = getAlertModalHeadline(entity, rowData.length, BULK_ACTIONS.deactivate, t)
    const primaryButtonText = getPrimaryButtonText(BULK_ACTIONS.deactivate)
    const modalText = <AlertModalText entity={entity} count={rowData.length} action={BULK_ACTIONS.deactivate} />

    const bulkDeactivateParams = [
      [{ name: 'ptDeactivated', value: true }],
      'endPointEdit',
      ENTITIES.pts,
      rowData,
      context
    ]

    const modalButton = (
      <ButtonPrimary
        modalButton
        content={primaryButtonText}
        onClick={() => {
          context.setModalOpen(false)
          toggleAllRowsSelected(false)
          applyBulkEdit(...bulkDeactivateParams)
        }}
      />
    )

    allActionButtons.push(
      <BulkActionButton
        key="deactivate"
        onClick={() => {
          context.setModalProps({
            headline: modalHeadline,
            content: modalText,
            buttonPrimary: modalButton,
            icon: 'icon-deactivate modal',
            type: MODAL_TYPES.alert
          })
          context.setModalOpen(true)
        }}>
        <BulkActionButtonContent>
          <BulkActionIcon className="svg-icon icon-deactivate" />
          <span>{t('deactivate')}</span>
        </BulkActionButtonContent>
      </BulkActionButton>
    )
  }

  if (actions?.includes(BULK_ACTIONS.delete)) {
    const modalHeadline = getAlertModalHeadline(entity, rowData.length, BULK_ACTIONS.delete, t)
    const primaryButtonText = getPrimaryButtonText(BULK_ACTIONS.delete)
    const modalText = <AlertModalText entity={entity} count={rowData.length} action={BULK_ACTIONS.delete} />
    const bulkDeleteParams = [
      [{ name: 'visibility', value: CUSTOMER_VISIBILITY_STATUS.deleted }],
      'endPointArchiveDelete',
      entity,
      rowData,
      context
    ]

    const modalButton = (
      <ButtonPrimary
        warning
        content={primaryButtonText}
        modalButton
        onClick={() => {
          context.setModalOpen(false)
          toggleAllRowsSelected(false)
          applyBulkEdit(...bulkDeleteParams)
        }}
      />
    )

    allActionButtons.push(
      <BulkActionButton
        key="delete"
        onClick={() => {
          context.setModalProps({
            headline: modalHeadline,
            content: modalText,
            buttonPrimary: modalButton,
            icon: 'icon-delete modal',
            type: MODAL_TYPES.alert
          })
          context.setModalOpen(true)
        }}>
        <BulkActionButtonContent>
          <BulkActionIcon className="svg-icon icon-delete" style={{ backgroundColor: 'var(--warning-color)' }} />
          <span style={{ color: 'var(--warning-color)' }}>{t('delete')}</span>
        </BulkActionButtonContent>
      </BulkActionButton>
    )
  }

  return allActionButtons
}

export default getActionButtons

const CsvActionButton = ({ rowData, entity, toggleAllRowsSelected, visibleColumns }) => {
  const t = useTranslate()
  const childRef = useRef(null)

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      childRef.current.handleClick()
    }
  }
  return (
    <BulkActionButton onKeyDown={handleKeyPress} key="downloadSelectionAsCsv" style={{ position: 'relative' }}>
      <BulkActionButtonContent>
        <BulkActionIcon className="svg-icon icon-csv" />
        <span>{t('exportCsv')}</span>
        <CsvDownload
          ref={childRef}
          rowData={rowData}
          entity={entity}
          toggleAllRowsSelected={toggleAllRowsSelected}
          visibleColumns={visibleColumns}
        />
      </BulkActionButtonContent>
    </BulkActionButton>
  )
}

const getPtNumbers = (rowData, entity) => {
  if (isPt(entity)) return rowData.map((pt) => pt.ptNumber)
  if (isParticipant(entity)) {
    return rowData.flatMap((p) => p.ptList.map((pt) => pt.ptNumber))
  }
}
