import {
  getTestTypeName,
  getDurationString,
  isPotentials,
  isTraits,
  isGoals,
  isPotentialsCrossTest,
  arrayToCsvString,
  getTranslatedReferenceGroupInfos,
  getTestLanguagesString
} from '../../utils/helper/Helper'
import { BaseInfo, InfoContent, ConfigLabel, CustomInputList } from '../table/tableElements'
import { useContext } from 'react'
import { AppContext } from '../../utils/context/AppContext'
import { CUSTOM_CONFIG_LABEL, DEFAULT_LANGUAGE } from '../../utils/constants/constants'
import TextWithInfoTooltip from '../textWithInfoTooltip/TextWithInfoTooltip'
import useTranslate from '../../utils/hooks/useTranslate'
import DateDisplay from '../dateDisplay/DateDisplay'

const ConfigInfos = ({ config, assessment, showModules = false, position, parentConfig }) => {
  const t = useTranslate()
  const context = useContext(AppContext)
  const isInWizard = position === 'addAssessmentWizard'

  const parentConfigObj = parentConfig || config.parentConfig // TestVariantForm passes parentConfig, AssessmentInfosTab passes parentConfig via config

  const reportModules = getReportModules({ config: config, assessment: assessment, t: t })
  const reportModulesTranslated = reportModules.map((module) => t(module))
  const moduleList = arrayToCsvString(reportModulesTranslated, ',')

  const parentConfigName = getParentConfigName(parentConfigObj, context)
  const testLanguagesString = getTestLanguagesString(parentConfigObj?.availableLanguages)

  const { referenceGroupTitle, referenceGroupDescription } = getTranslatedReferenceGroupInfos(config, context.language)
  const configVersionComment =
    config.configVersionComment[context.language] || config.configVersionComment[DEFAULT_LANGUAGE]

  return (
    <CustomInputList style={{ gap: 'var(--space-3)' }}>
      <Info {...{ position }} label="testType" content={getTestTypeName(config.configType)} />
      <Info {...{ position }} label="testName" content={parentConfigName} />
      {referenceGroupTitle && (
        <Info
          {...{ position }}
          label="referenceGroup"
          content={<TextWithInfoTooltip text={referenceGroupTitle} info={referenceGroupDescription} />}
        />
      )}
      {isInWizard && <Info {...{ position }} label="availableLanguages" content={testLanguagesString} />}
      <Info {...{ position }} label="duration" content={getDurationString(config.configDuration, t).long} />
      {config.configVersion && (
        <Info
          {...{ position }}
          label="Version"
          content={<TextWithInfoTooltip text={config.configVersion} info={configVersionComment} />}
        />
      )}
      <Info {...{ position }} label="lastUpdate" content={<DateDisplay date={config.created} />} />
      {showModules && reportModules.length > 0 && <Info {...{ position }} label="reportModules" content={moduleList} />}
    </CustomInputList>
  )
}

export default ConfigInfos

const getReportModules = ({ config, assessment }) => {
  const reportModules = []

  if (isPotentials(config.configType)) {
    reportModules.push('performanceProfile')
  }

  if (isPotentialsCrossTest(config.configType)) {
    reportModules.push('crossTestResult')
  }

  if (isTraits(config.configType)) {
    reportModules.push('personalityProfile')
  }

  if (isGoals(config.configType)) {
    reportModules.push('interestProfile')
  }

  if (assessment) {
    const modules = assessment.reportModules
    if (modules.interview) {
      reportModules.push('interviewQuestions')
    }

    if (modules.potentialsRisks) {
      reportModules.push('potentialsAndRisks')
    }

    if (modules.verbalisation) {
      reportModules.push('resultDescription')
    }

    if (modules.matching) {
      reportModules.push('jobMatching')
    }

    if (modules.feedbackAvailable) {
      reportModules.push('participantFeedback')
    }

    if (modules.potentialsStatistics) {
      reportModules.push('statisticsAndAnomalies')
    }

    if (modules.freeTextAnswers) {
      reportModules.push('openTextResponses')
    }

    if (modules.radarPlot) {
      reportModules.push('radarPlot')
    }
  }
  return reportModules
}

const getParentConfigName = (parentConfigObj, context) => {
  const translatedName = parentConfigObj.translatedName
  return translatedName[context.language] || translatedName[DEFAULT_LANGUAGE] || CUSTOM_CONFIG_LABEL
}

const Info = ({ position, label, content }) => {
  const t = useTranslate()
  return (
    <BaseInfo position={position}>
      <ConfigLabel className="text-secondary" position={position}>
        {t(label)}
      </ConfigLabel>
      <InfoContent>{t(content)}</InfoContent>
    </BaseInfo>
  )
}
