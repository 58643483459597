import styled from 'styled-components'
import { Label } from '../../../utils/elements/miscElements'
import useTranslate from '../../../utils/hooks/useTranslate'
import { getFormattedNumber } from '../../../utils/helper/Helper'
import { useContext } from 'react'
import { AppContext } from '../../../utils/context/AppContext'

const NormPreview = ({ normTable }) => {
  const t = useTranslate()
  const { language } = useContext(AppContext)
  const hasBoundaryErrors = normTable.some((row) => row.upperTooLow)
  
  return (
    <OuterContainer>
      <Label>{t('preview')}</Label>
      <div>
        <Bar>
          {!hasBoundaryErrors &&
            normTable.map((row, i) => {
              const width = row.upper_limit - row.lower_limit
              return (
                <Element key={row.upper_limit + i} width={width}>
                  {getFormattedNumber(row.grade, language)}
                </Element>
              )
            })}
        </Bar>
        <SectionContainer>
          {Array.from(Array(10)).map((_, i) => (
            <Section key={`section${i}`} value={i * 10} />
          ))}
        </SectionContainer>
      </div>
    </OuterContainer>
  )
}

export default NormPreview

const SectionContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  height: var(--space-5);
  width: 100%;
  div:nth-child(1),
  div:nth-child(10) {
    background-color: #000;
  }
  div:nth-child(2),
  div:nth-child(3),
  div:nth-child(8),
  div:nth-child(9) {
    background-color: #4b4257;
  }
  div:nth-child(4),
  div:nth-child(5),
  div:nth-child(6),
  div:nth-child(7) {
    background-color: #726085;
  }
`

const Section = styled.div`
  position: relative;
  &:first-child {
    border-bottom-left-radius: var(--bar-radius);
  }
  &:last-child {
    border-bottom-right-radius: var(--bar-radius);
  }
  &:not(:last-child) {
    border-right: 1px solid var(--norm-preview-section-border-color);
  }
  &::before,
  &:after {
    position: absolute;
    bottom: -20px;
    font-size: var(--fs-2);
    color: var(--text-color-secondary);
  }
  &:before {
    content: '${(props) => props.value}';
    left: -6px;
  }
  &:first-child:before {
    left: 0;
  }
  &:last-child:after {
    content: '100';
    right: 0;
  }
`

const Element = styled.div`
  width: ${(props) => `${props.width}%`};
  height: 100%;
  border-right: 1px solid var(--norm-preview-section-border-color);
  &:last-child {
    border-right: none;
  }
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
`

const OuterContainer = styled.div`
  display: grid;
  gap: var(--space-2);
  --bar-radius: var(--bdr-2);
  margin-bottom: var(--space-5);
`

const Bar = styled.div`
  height: var(--space-6);
  background-color: var(--norm-preview-background);
  display: flex;
  width: 100%;
  height: var(--space-7);
  border-top-left-radius: var(--bar-radius);
  border-top-right-radius: var(--bar-radius);
`
