import styled from 'styled-components'
import { ButtonPrimary } from '../../../utils/elements/miscElements'
import { useState } from 'react'
import { useContext } from 'react'
import { AppContext } from '../../../utils/context/AppContext'
import { DEFAULT_LOGO_PATH, MODAL_TYPES } from '../../../utils/constants/constants'
import Dropzone from 'react-dropzone'
import sessionHandler from '../../../utils/helper/sessionHandler'
import { fetchData, getApiUrl, handleFetchErrors, updateEntities } from '../../../utils/helper/Helper'
import { CustomersFile } from '../../../entities/CustomersFile'
import { getDropzoneBorderColor } from '../../addParticipantWizard/subComponents/UploadDropZone'
import { EditDiscardButton } from '../../../components/customInput/customInputElements'
import useTranslate from '../../../utils/hooks/useTranslate'

const MediaLibrary = ({ onApply, currentFilePath }) => {
  const context = useContext(AppContext)
  const t = useTranslate()
  const { completeDataSet } = context

  const defaultLogoFile = new CustomersFile({
    filename: 'default',
    path: DEFAULT_LOGO_PATH
  })

  const files = [defaultLogoFile, ...completeDataSet.customersFiles]

  const [selectedFilePath, setSelectedFilePath] = useState(currentFilePath)
  const [errorMessage, setErrorMessage] = useState('')
  const selectedFile = files.find((file) => file.path === selectedFilePath)

  const acceptedFileTypes = ['image/png', 'image/jpeg']
  const handleFiles = (acceptedFiles) => {
    const formData = new FormData()
    const file = acceptedFiles[0]
    formData.append('file', file)
    uploadFile(formData)
  }

  const uploadFile = async (formData) => {
    const url = getApiUrl('upload_image')
    const requestOptions = {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${sessionHandler.getSessionId()}`
      },
      body: formData,
      crossDomain: true
    }
    try {
      const response = await fetch(url, requestOptions)
      const responseData = await handleFetchErrors(response, context)
      updateEntities(responseData.response.data, context)
    } catch (e) {
      console.error(e)
      setErrorMessage(t('uploadFailed'))
      setTimeout(() => setErrorMessage(''), 5000)
    }
  }

  const deleteFile = async (e) => {
    e.preventDefault()
    const payload = { filename: selectedFile.filename }
    try {
      const responseData = await fetchData(payload, 'delete_image', context, null, false)
      setSelectedFilePath(DEFAULT_LOGO_PATH)
      updateEntities(responseData.response.data, context)
    } catch (e) {
      console.error(e)
    }
  }

  return (
    <div>
      <InnerContainer>
        <Dropzone accept={acceptedFileTypes} onDrop={(acceptedFiles) => handleFiles(acceptedFiles)}>
          {({ getRootProps, getInputProps, isDragAccept, isFocused, isDragReject }) => (
            <ImageContainer {...getRootProps({ isFocused, isDragAccept, isDragReject })} dummy>
              <div>
                <input {...getInputProps()} />
                <Icon className="svg-icon icon-file-upload" />
                <div style={{ fontSize: 'var(--fs-1)' }}>{t('uploadLogo')}</div>
              </div>
            </ImageContainer>
          )}
        </Dropzone>
        {files.map((file) => {
          const isSelected = file.path === selectedFilePath
          return (
            <ImageContainer key={file.filename} onClick={() => setSelectedFilePath(file.path)} isSelected={isSelected}>
              <img src={file.path} />
            </ImageContainer>
          )
        })}
      </InnerContainer>

      <UploadHint className="text-secondary" hasError={errorMessage}>
        {errorMessage ? errorMessage : t('fileUploadHint')}
      </UploadHint>

      <Footer>
        {selectedFile?.canDelete && (
          <EditDiscardButton style={{ height: '36px', width: '40px' }} onClick={(e) => deleteFile(e)}>
            <span className="svg-icon icon-trash"></span>
          </EditDiscardButton>
        )}
        <ButtonPrimary
          disabled={!selectedFile}
          style={{ marginLeft: 'auto', minHeight: '36px' }}
          content="apply"
          onClick={() => onApply(selectedFile.path)}
        />
      </Footer>
    </div>
  )
}

export default MediaLibrary

const Icon = styled.span`
  width: var(--icon-2);
  height: var(--icon-2);
`

const UploadHint = styled.div`
  margin-bottom: var(--space-4);
  font-weight: var(--fw-1);
  color: ${(props) => (props.hasError ? 'var(--error-message-color)' : '')};
`

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: var(--space-3);
  button {
    flex-grow: 0;
  }
`

const InnerContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 100px);
  gap: var(--space-4);
  margin: var(--space-4) -4px 0 -4px;
  padding: 4px;
  max-height: 240px;
  overflow: scroll;
`

const ImageContainer = styled.button`
  background-color: transparent;
  width: 100px;
  height: 100px;
  overflow: hidden;
  border-width: ${(props) => (props.isSelected ? '2px' : '1px')};
  border-color: ${(props) => (props.isSelected ? 'var(--color-focus-outline)' : getDropzoneBorderColor(props))};
  border-style: ${(props) => (props.dummy ? 'dashed' : 'solid')};
  border-radius: var(--bdr-2);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  padding: ${(props) => (props.isSelected ? '3px' : 'var(--space-2)')};
  gap: var(--space-2);
  cursor: pointer;
  transition: border-color var(--hover-duration);
  &:hover {
    border-color: ${(props) => (props.dummy ? 'var(--grey-700)' : '')};
  }
  img {
    width: 100%;
  }
`

export const toggleMediaLibrary = (context, template, updateFn) => {
  context.setModalProps({
    headline: 'mediaLibrary',
    showCloseButton: true,
    hideFooter: true,
    type: MODAL_TYPES.mediaLibrary,
    content: (
      <MediaLibrary
        currentFilePath={template.logoPath}
        onApply={(value) => {
          updateFn('logoPath', value)
          context.setModalOpen(false)
        }}
      />
    )
  })
  context.setModalOpen(true)
}
