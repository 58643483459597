import styled from 'styled-components'
import { getLightnessFromHex, isValidHex } from '../../../utils/helper/Helper'

const CustomColorPicker = ({ template, fieldName, updateFn }) => {
  const currentValue = template?.[fieldName] || ''
  const lightness = getLightnessFromHex(currentValue)
  const LIGHTNESS_BORDER_THRESHOLD = 0.9
  const HEX_MAX_CHARS = 7

  const updateHexValue = (hex) => {
    if (hex.length > HEX_MAX_CHARS) {
      return
    }
    updateFn(fieldName, hex)
  }

  return (
    <ColorHexValueWrapper>
      <ColorInputBorder showBorder={lightness > LIGHTNESS_BORDER_THRESHOLD}>
        <ColorPicker type="color" value={currentValue} onChange={(e) => updateFn(fieldName, e.target.value)} />
      </ColorInputBorder>
      <input
        type="text"
        style={{ width: '80px' }}
        value={currentValue}
        onChange={(e) => updateHexValue(e.target.value)}
        className={!isValidHex(currentValue) ? 'has-error' : ''}
      />
    </ColorHexValueWrapper>
  )
}

export default CustomColorPicker

const ColorPicker = styled.input`
  position: absolute;
  width: 100px;
  height: 50px;
  border: none;
  padding: 0;
  top: -8px;
  left: -5px;
  cursor: pointer;
`

const ColorHexValueWrapper = styled.div`
  display: flex;
  gap: var(--space-2);
`

const ColorInputBorder = styled.div`
  position: relative;
  overflow: hidden;
  width: 40px;
  height: 36px;
  border-radius: 4px;
  border-style: solid;
  border-color: var(--border-grey);
  border-width: ${(props) => (props.showBorder ? '1px' : 0)};
  &:focus-within {
    box-shadow: var(--sh-outline);
  }
`
