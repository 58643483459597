export class BillingInfo {
  constructor({ ...props }) {
    this.entity = 'BillingInfo'
    this.billingCustomer = props.billingCustomer || ''
    this.billingStreet = props.billingStreet || ''
    this.billingAddressAddition = props.billingAddressAddition || ''
    this.billingZipCode = props.billingZipCode || ''
    this.billingCity = props.billingCity || ''
    this.billingCountry = props.billingCountry || ''
    this.billingSalutation = props.billingSalutation || ''
    this.billingTitle = props.billingTitle || ''
    this.billingFirstName = props.billingFirstName || ''
    this.billingLastName = props.billingLastName || ''
    this.billingEmail = props.billingEmail || ''
    this.billingEmailCc = props.billingEmailCc || ''
    this.billingCustomField1Label = props.billingCustomField1Label || ''
    this.billingCustomField1Content = props.billingCustomField1Content || ''
    this.billingCustomField2Label = props.billingCustomField2Label || ''
    this.billingCustomField2Content = props.billingCustomField2Content || ''
  }
}
