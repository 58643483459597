import { useState, useContext } from 'react'
import PageGroup from '../../components/pageGroup/PageGroup'
import { setStatusBar } from '../../utils/helper/Helper'
import { AppContext } from '../../utils/context/AppContext'
import { ButtonPrimary, ButtonSecondary } from '../../utils/elements/miscElements'
import { MAX_CHARS, STATUS_BAR_TYPES } from '../../utils/constants/constants'
import { PAGES } from '../../utils/constants/pages'
import ProcessNameAndDesign from './subPages/ProcessNameAndDesign'
import SetCapabilitiesForProcess from './subPages/SetCapabilitiesForProcess'
import CheckInput from './subPages/CheckInput'
import AddProcessSuccessPage from './subPages/AddProcessSuccessPage'
import { addProcess, getFilteredPages, getInitialCapabilitiesForContacts } from './addProcessUtils'
import useTranslate from '../../utils/hooks/useTranslate'
import { getDefaultDesign, getDesignOptions } from '../../utils/helper/designUtils'
import useRedirect from '../../utils/hooks/useRedirect'
import { isValidName } from '../addAssessmentWizard/addAssessmentUtils'

const AddProcessWizard = () => {
  const t = useTranslate()
  const redirect = useRedirect()

  const context = useContext(AppContext)
  const completeDataSet = context.completeDataSet
  const designOptions = getDesignOptions(completeDataSet.designOptions)
  const defaultDesign = getDefaultDesign(designOptions)

  const [activePageId, setActivePageId] = useState(0)
  const [newData, setNewData] = useState({})
  const [isLoading, setIsLoading] = useState(false)

  const [data, setData] = useState({
    processName: '',
    processDescription: '',
    elektryonTemplate: defaultDesign.value,
    contactCapabilities: getInitialCapabilitiesForContacts(completeDataSet.contacts),
    useProcessResult: true
  })

  const processNameIsValid = isValidName(data.processName, MAX_CHARS.processName)

  const pages = {
    processNameAndDesign: {
      content: <ProcessNameAndDesign {...{ data, setData, designOptions }} />,
      title: t('nameAndDesign'),
      buttonSecondary: <ButtonSecondary content="cancel" onClick={() => redirect(PAGES.processes)} />,
      buttonPrimary: (
        <ButtonPrimary
          content="next"
          disabled={!processNameIsValid}
          onClick={() => setActivePageId(activePageId + 1)}
        />
      ),
      largeButtons: true
    },
    setCapabilitiesForProcess: {
      content: <SetCapabilitiesForProcess data={data} setData={setData} />,
      title: t('permissions'),
      buttonSecondary: <ButtonSecondary content="back" onClick={() => setActivePageId(activePageId - 1)} />,
      buttonPrimary: <ButtonPrimary content="next" onClick={() => setActivePageId(activePageId + 1)} />,
      largeButtons: true
    },
    checkInput: {
      content: <CheckInput data={data} designOptions={designOptions} />,
      title: t('checkInputs'),
      largeButtons: true,
      buttonSecondary: <ButtonSecondary content="back" onClick={() => setActivePageId(activePageId - 1)} />,
      buttonPrimary: (
        <ButtonPrimary
          disabled={!processNameIsValid || isLoading}
          style={{ whiteSpace: 'nowrap' }}
          content="createProcess"
          onClick={() => {
            setIsLoading(true)
            setStatusBar({
              controller: context.statusBarController,
              type: STATUS_BAR_TYPES.loading,
              text: 'processingInProgress',
              setVisible: true
            })

            addProcess(data, context)
              .then((responseData) => {
                setActivePageId(activePageId + 1)
                setNewData(responseData.response)
                setStatusBar({
                  controller: context.statusBarController,
                  type: STATUS_BAR_TYPES.success,
                  text: 'processSuccessfullyCreated'
                })
              })
              .catch((e) => {
                redirect(PAGES.processes)
                console.error(e)
              })
          }}
        />
      )
    },
    addProcessSuccessPage: {
      content: <AddProcessSuccessPage />,
      hidePageIndicator: true,
      buttonSecondary: (
        <ButtonSecondary
          content="viewProcess"
          onClick={() => {
            const params = [{ id: 'processName', value: newData?.data?.process?.processName }]
            redirect(PAGES.processes, params)
          }}
        />
      ),
      buttonPrimary: (
        <ButtonPrimary
          content="addTest"
          onClick={() => {
            const params = [{ id: 'processUuid', value: newData?.data?.process?.processUuid }]
            redirect(PAGES.newTest, params)
          }}
        />
      )
    }
  }

  const filteredPages = getFilteredPages(pages, completeDataSet.contacts)

  return <PageGroup pages={filteredPages} activePageId={activePageId} />
}

export default AddProcessWizard
