import styled from 'styled-components'
import { GreyButton } from '../../../utils/elements/miscElements'
import { useContext } from 'react'
import { AppContext } from '../../../utils/context/AppContext'
import { MODAL_TYPES } from '../../../utils/constants/constants'
import useTranslate from '../../../utils/hooks/useTranslate'
export const RenameButton = styled.button`
  background-color: transparent;
  height: 18px !important;
  font-size: var(--fs-2) !important;
`

export const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: var(--space-3);
  margin-top: var(--space-3);
`

export const MainButtonContainer = styled.div`
  display: flex;
  margin-left: auto;
  gap: var(--space-3);
  button {
    width: 150px;
  }
`

export const ButtonContainer = styled.div`
  display: grid;
  grid-template-columns: ${(props) => (props.showLanguageSelect ? '1fr 1fr' : '1fr 1fr')};
  align-items: flex-end;
  gap: var(--space-3);
  button {
    min-height: unset;
    font-weight: unset;
    height: 36px;
    font-size: 13px;
    width: auto;
    justify-self: flex-end;
  }
`

export const InnerContainer = styled.div`
  display: grid;
  gap: var(--space-5);
  position: relative;
`

export const Container = styled.div`
  min-width: 500px;
  margin-bottom: var(--space-7);
  position: relative;
  container-name: editor-container;
  container-type: inline-size;
`

export const LabelSelectContainer = styled.div`
  display: grid;
  gap: 2px;
`

export const LabelRenameButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
`

export const HelpButton = ({ content, label }) => {
  const context = useContext(AppContext)
  const t = useTranslate()

  const openModal = () => {
    context.setModalProps({
      headline: t(label),
      content: content,
      showCloseButton: true,
      hideFooter: true,
      type: MODAL_TYPES.help
    })
    context.setModalOpen(true)
  }
  return <HelpButtonElement onClick={openModal}>{t('help')}</HelpButtonElement>
}

const HelpButtonElement = styled(GreyButton)`
  position: absolute;
  display: inline-flex;
  right: 0;
  top: -16px;
  z-index: 9;
  height: 24px;
  padding: 4px 8px;
  font-size: var(--fs-2);
  gap: var(--space-2);
`
