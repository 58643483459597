import Config from '../utils/constants/config'
import { createDate } from '../utils/helper/dateTimeHelper'

export class CustomersFile {
  constructor({ ...props }) {
    this.entity = 'CustomersFile'
    this.filename = props.filename
    this.folder = props.folder
    this.aspectRatio = props.aspectRatio
    this.path = props.path || `${Config.baseUrl}/uploads/customers_files/${this.folder}/${this.filename}`
    this.created = createDate(props.created)
    this.createdBy = props.createdBy
    this.visibility = props.visibility
    this.canDelete = this.filename !== 'default'
  }
}
