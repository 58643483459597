import useTranslate from '../../../utils/hooks/useTranslate'
import { getDefaultNewTitle } from '../sendEmailUtils'
import styled from 'styled-components'
import { setState } from '../../../utils/helper/Helper'
import { useEffect } from 'react'
import { useMemo } from 'react'
import EmailHtmlEditor from '../subComponents/EmailHtmlEditor'
import EmailEditorSidebar from '../subComponents/EmailEditorSidebar'
import ScrollContainer from '../../../components/scrollContainer/ScrollContainer'
import { PageHeadline } from '../../../utils/elements/miscElements'

const EmailTemplate = ({ data, setData }) => {
  const t = useTranslate()

  const template = useMemo(() => {
    return data.availableTemplates.find((t) => t.publicKey === data.selectedTemplateKey)
  }, [data.availableTemplates, data.selectedTemplateKey])

  useEffect(() => {
    const newTemplateName = getDefaultNewTitle(template.name, data.saveMethod, t)
    setState(setData, 'newTemplateName', newTemplateName)
  }, [template.name, data.saveMethod])

  const updateFn = (field, value) => updateTemplate(data, setData, field, value)

  return (
    <>
      <PageHeadline>{t('subjectAndContent')}</PageHeadline>
      <EmailTemplateContainer>
        <ScrollContainerForHtmlEditor>
          <EmailHtmlEditor template={template} data={data} updateFn={updateFn} editMode />
        </ScrollContainerForHtmlEditor>
        <ScrollContainer>
          <EmailEditorSidebar template={template} data={data} setData={setData} updateFn={updateFn} />
        </ScrollContainer>
      </EmailTemplateContainer>
    </>
  )
}

export default EmailTemplate

export const ScrollContainerForHtmlEditor = styled(ScrollContainer).attrs({ className: 'scroll-container' })`
  padding-left: 5px;
  padding-top: 5px;
  padding-right: 5px;
  margin: -5px -5px 0 -5px;
`

export const EmailTemplateContainer = styled.div.attrs({ className: 'email-template-container' })`
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: var(--space-7);
  overflow: hidden;
  padding-left: 5px;
  margin-left: -5px;
`

export const AccessDataPlaceholder = styled.div`
  border-radius: var(--bdr-2);
  padding: var(--space-3);
  line-height: 18px !important;
  text-align: center;
  background-color: var(--box-background);
  font-weight: var(--fw-2);
  color: var(--text-color-primary);
`

export const TextArea = styled.textarea`
  width: 100%;
  border: 1px solid var(--border-grey);
  border-radius: var(--bdr-2);
  padding: var(--space-3);
  line-height: var(--lh-3);
  resize: none;
`

export const updateTemplate = (data, setData, fieldName, value) => {
  const updatedTemplates = data.availableTemplates.map((template) =>
    template.publicKey === data.selectedTemplateKey ? { ...template, [fieldName]: value, isDirty: true } : template
  )

  setData((prev) => ({
    ...prev,
    availableTemplates: updatedTemplates
  }))
}
