import RowActions from '../components/table/RowActions'
import { ENTITIES } from '../utils/constants/constants'
import { createDate } from '../utils/helper/dateTimeHelper'
import { MainEntity } from './MainEntity'
import { PtInvitationTemplate } from './PtInvitationTemplate'

export class ParticipantMailLog extends MainEntity {
  constructor({ ...props }) {
    super()
    this.entity = 'ParticipantMailLog'
    this.email_nr = props.email_nr
    this.emailType = props.emailType === 1 ? 'invitation' : 'reminder'
    this.mailAddress = props.mailAddress
    this.created = createDate(props.created)
    this.createdBy = props.createdBy
    this.pNr = props.pNr
    this.ptNrs = props.ptNrs
    this.visibility = props.visibility
    this.relatedParticipant = {}
    this.replyToEmail = props.mailData.replyToEmail
    this.replyToName = props.mailData.replyToName
    this.actions = <RowActions row={this} entity={ENTITIES.participantMailLogs} />
    this.template = new PtInvitationTemplate(props)
    this.ptData = props.mailData.pt.map((pt) => new PtData(pt))
    this.assessmentNames = this.ptData.map((pt) => pt.testName)
    this.formattedEmailData = [
      {
        pNr: this.pNr,
        ptList: this.ptNrs,
        salutation: '' // Salutation is already part of topText
      }
    ]
  }
  addRelatedElements(completeDataSet) {
    const { participants } = completeDataSet
    this.relatedParticipant = participants.find((p) => p.pNr === this.pNr) || {}
  }
}

class PtData {
  constructor({ ...props }) {
    this.ptNr = props.pt_nr || ''
    this.testName = props.test_name || ''
    this.validFrom = props.valid_from || ''
    this.validUntil = props.valid_until || ''
    this.token = props.token || ''
    this.requiredMaterials = props.required_materials || ''
  }
}
