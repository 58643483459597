import { useContext } from 'react'
import styled from 'styled-components'
import { AppContext } from '../../utils/context/AppContext'
import { getFormattedPrice, getFormattedNumber } from '../../utils/helper/Helper'
import useTranslate from '../../utils/hooks/useTranslate'

const InfoPopUp = ({ productPrice }) => {
  const context = useContext(AppContext)
  const customer = context.completeDataSet.customer
  const t = useTranslate()

  return (
    <PopUpWrapper>
      <PopUpHeadline>
        <span>Credits</span>
        <span>{t('creditPrice')}</span>
        <span>{t('testPrice')}</span>
      </PopUpHeadline>
      <PopUpBody>
        {customer.creditPrices.map((creditPrice, i) => (
          <CreditPriceEntry key={'creditPrice' + i}>
            <span>{getFormattedNumber(creditPrice.count, context.language)}</span>
            <span>{getFormattedPrice(creditPrice.price, context.language)}</span>
            <span>{getFormattedPrice(creditPrice.price * productPrice, context.language)}</span>
          </CreditPriceEntry>
        ))}
      </PopUpBody>
    </PopUpWrapper>
  )
}

export default InfoPopUp

const PopUpWrapper = styled.div`
  margin-top: var(--space-2);
  font-size: var(--fs-3);
  span {
    text-align: right;
  }
`

const PopUpBody = styled.ul`
  margin: 0;
  padding: var(--space-3) 0 0 0;
  display: grid;
  gap: var(--space-3);
`

const CreditPriceEntry = styled.li`
  display: grid;
  grid-template-columns: 50px 80px 80px;
  gap: var(--space-6);
  line-height: var(--lh-3);
`
const PopUpHeadline = styled.div`
  display: grid;
  grid-template-columns: 50px 80px 80px;
  gap: var(--space-6);
  font-weight: 600;
  border-bottom: 1px solid #bfbfbf;
  padding-bottom: var(--space-3);
`
