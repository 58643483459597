import React, { useState, useEffect, useContext } from 'react'
import PageGroup from '../../components/pageGroup/PageGroup'
import AssessmentNameForm from './subPages/AssessmentNameForm'
import TestVariantForm from './subPages/TestVariantForm'
import SelectParentConfigForm from './subPages/SelectParentConfigForm'
import TimeSettingsForm from './subPages/TimeSettingsForm'
import AdvancedSettingsForm from './subPages/AdvancedSettingsForm'
import SuccessPage from './subPages/SuccessPage'
import CheckInput from './subPages/CheckInput'
import ReportConfigForm from './subPages/ReportConfigForm'

import {
  addAssessment,
  clearReportModules,
  getWeightForNewTest,
  getOptionDescriptions,
  getProcessFromUrlParams,
  lazyLoadDimensionData,
  setTraitsTargetGroup,
  validateWizardData
} from './addAssessmentUtils'
import { STATUS_BAR_TYPES } from '../../utils/constants/constants'
import { AppContext } from '../../utils/context/AppContext'
import { ButtonPrimary, ButtonSecondary } from '../../utils/elements/miscElements'
import { useLocation } from 'react-router-dom'
import { setStatusBar, setState } from '../../utils/helper/Helper'
import { PAGES } from '../../utils/constants/pages'
import { addProcess, getInitialCapabilitiesForContacts } from '../addProcessWizard/addProcessUtils'
import useTranslate from '../../utils/hooks/useTranslate'
import { getDefaultDesign, getDesignOptions } from '../../utils/helper/designUtils'
import useRedirect from '../../utils/hooks/useRedirect'

const AddAssessmentWizard = () => {
  const t = useTranslate()
  const redirect = useRedirect()

  const [startedDurationIsValid, setStartedDurationIsValid] = useState(true)
  const [activePageId, setActivePageId] = useState(0)
  const [newData, setNewData] = useState({})
  const context = useContext(AppContext)
  const completeDataSet = context.completeDataSet

  const designOptions = getDesignOptions(completeDataSet.designOptions)
  const defaultDesign = getDefaultDesign(designOptions)

  const [data, setData] = useState({
    assessmentName: '',
    assessmentDescription: '',
    processUuid: '',
    parentConfig: null,
    configUuid: '',
    configVariant: '',
    configType: '',
    targetGroup: '',
    showValidFrom: false,
    showValidUntil: false,
    showInvitedDuration: false,
    showStartedDuration: false,
    validFrom: '',
    validUntil: '',
    invitedDuration: '',
    startedDuration: '',
    isSupervised: false,
    useChildItems: true,
    inHub: true,
    languageIds: [1],
    reportModulesArray: [],
    jobType: null,
    referenceAssessmentUuid: null,
    createNewProcess: false,
    processName: '',
    processDescription: '',
    useProcessResult: false,
    elektryonTemplate: defaultDesign.value,
    weight: 0,
    contactCapabilities: getInitialCapabilitiesForContacts(completeDataSet.contacts),
    loadedDimensions: [],
    useCameraSupervision: 0,
    tabTracking: true,
    selectedRowIds: {}
  })

  const { processIsValid, assessmentNameIsValid, refTestIsValid, testDatesValid } = validateWizardData(data)

  useEffect(() => {
    if (data.parentConfig) {
      clearReportModules(setData)
      setTraitsTargetGroup(data.parentConfig, setData)
      lazyLoadDimensionData(data, setData, context)
      setState(setData, 'languageIds', [data.parentConfig.availableLanguages[0].value])
    }
  }, [data.parentConfig?.uuid])

  useEffect(() => {
    const weight = getWeightForNewTest(data, completeDataSet)
    setData((prev) => ({
      ...prev,
      weight: weight,
      useProcessResult: weight > 0
    }))
  }, [data.createNewProcess, data.processUuid, data.configType])

  const location = useLocation()
  const optionDescriptions = getOptionDescriptions(t)

  useEffect(() => {
    getProcessFromUrlParams(location, setData)
  }, [location])

  return (
    <PageGroup
      pages={[
        {
          content: (
            <SelectParentConfigForm
              selectedRowIds={data.selectedRowIds}
              parentConfigs={completeDataSet.parentConfigs}
              setData={setData}
            />
          ),
          title: 'Test',
          buttonSecondary: <ButtonSecondary content="cancel" onClick={() => redirect(PAGES.assessments)} />,
          buttonPrimary: (
            <ButtonPrimary
              content="next"
              onClick={() => setActivePageId(activePageId + 1)}
              disabled={!data.parentConfig}
            />
          )
        },
        {
          content: <TestVariantForm data={data} setData={setData} />,
          title: t('variant'),
          buttonSecondary: <ButtonSecondary content="back" onClick={() => setActivePageId(activePageId - 1)} />,
          buttonPrimary: <ButtonPrimary content="next" onClick={() => setActivePageId(activePageId + 1)} />
        },
        {
          content: <AssessmentNameForm data={data} setData={setData} optionDescriptions={optionDescriptions} />,
          title: t('testNameAndProcess'),
          buttonSecondary: <ButtonSecondary content="back" onClick={() => setActivePageId(activePageId - 1)} />,
          buttonPrimary: (
            <ButtonPrimary
              content="next"
              onClick={() => setActivePageId(activePageId + 1)}
              disabled={!processIsValid || !assessmentNameIsValid || !refTestIsValid}
            />
          )
        },
        {
          content: (
            <TimeSettingsForm {...{ data, setData, optionDescriptions, testDatesValid, setStartedDurationIsValid }} />
          ),
          title: t('timePeriod'),
          buttonSecondary: <ButtonSecondary content="back" onClick={() => setActivePageId(activePageId - 1)} />,
          buttonPrimary: (
            <ButtonPrimary
              content="next"
              disabled={!testDatesValid || !startedDurationIsValid}
              onClick={() => setActivePageId(activePageId + 1)}
            />
          )
        },
        {
          content: <AdvancedSettingsForm data={data} setData={setData} optionDescriptions={optionDescriptions} />,
          title: t('additionalOptions'),
          buttonSecondary: <ButtonSecondary content="back" onClick={() => setActivePageId(activePageId - 1)} />,
          buttonPrimary: (
            <ButtonPrimary
              content="next"
              onClick={() => setActivePageId(activePageId + 1)}
              disabled={data.languageIds.length === 0}
            />
          )
        },
        {
          content: <ReportConfigForm data={data} setData={setData} />,
          title: t('reportModules'),
          buttonSecondary: <ButtonSecondary content="back" onClick={() => setActivePageId(activePageId - 1)} />,
          buttonPrimary: <ButtonPrimary content="next" onClick={() => setActivePageId(activePageId + 1)} />
        },
        {
          content: <CheckInput data={data} />,
          title: t('checkInputs'),
          buttonSecondary: <ButtonSecondary content="back" onClick={() => setActivePageId(activePageId - 1)} />,
          buttonPrimary: (
            <ButtonPrimary
              content="createTest"
              onClick={() => {
                setStatusBar({
                  controller: context.statusBarController,
                  type: STATUS_BAR_TYPES.loading,
                  text: 'processingInProgress',
                  setVisible: true
                })
                if (data.createNewProcess) {
                  addProcess(data, context)
                    .then((response) => {
                      const newProcessUuid = response.response.data.process.processUuid
                      addAssessment(data, context, newProcessUuid)
                        .then((responseData) => {
                          setActivePageId(activePageId + 1)
                          setNewData(responseData.response.data)
                          setStatusBar({
                            controller: context.statusBarController,
                            type: STATUS_BAR_TYPES.success,
                            text: 'processAndTestCreated'
                          })
                        })
                        .catch((e) => console.error(e))
                    })
                    .catch((e) => console.error(e))
                } else {
                  addAssessment(data, context)
                    .then((responseData) => {
                      setActivePageId(activePageId + 1)
                      setNewData(responseData.response.data)
                      setStatusBar({
                        controller: context.statusBarController,
                        type: STATUS_BAR_TYPES.success,
                        text: 'testSuccessfullyCreated'
                      })
                    })
                    .catch((e) => console.error(e))
                }
              }}
            />
          )
        },
        {
          content: <SuccessPage newData={newData} />,
          hidePageIndicator: true,
          buttonSecondary: (
            <ButtonSecondary
              content="viewTest"
              onClick={() => {
                const params = [
                  { id: 'processName', value: newData.processes[0].processName },
                  { id: 'assessmentName', value: data.assessmentName }
                ]
                redirect(PAGES.assessments, params)
              }}
            />
          ),
          buttonPrimary: (
            <ButtonPrimary
              content="addParticipants"
              onClick={() => {
                const params = [
                  { id: 'processUuid', value: newData.processes[0].processUuid },
                  { id: 'assessmentUuid', value: newData.assessment.assessmentUuid }
                ]
                redirect(PAGES.newParticipant, params)
              }}
            />
          )
        }
      ]}
      activePageId={activePageId}
    />
  )
}

export default AddAssessmentWizard
