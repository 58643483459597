import {
  API_DATA,
  MAX_CHARS,
  REPORT_MODULES,
  THE_FUTURE,
  THE_PAST,
  TRAITS_TARGET_GROUPS
} from '../../utils/constants/constants'
import { createDate, formatDate } from '../../utils/helper/dateTimeHelper'
import {
  checkDemoStatus,
  isTraits,
  setState,
  updateEntities,
  fetchData,
  hasSpecialChars,
  isCrossTest,
  isPotentials
} from '../../utils/helper/Helper'
import { getParamsJson } from '../../utils/helper/urlParamsHelper'

export const getOptionDescriptions = (t) => ({
  process: 'testAssignmentExplanation',
  processShort: 'testAssignmentExplanationShort',
  referenceAssessment: 'crossTestReferenceExplanation',
  assessmentName: `${t('nameVisibleToCandidates')} (${t('maxChars', MAX_CHARS.testName)})`,
  validFrom: 'validFromExplanation',
  validUntil: 'validUntilExplanation',
  invitedDuration: 'timerExplanation',
  startedDuration: 'startedDurationExplanation',
  isSupervised: 'statisticsRequirement',
  useChildItems: 'useChildItemsExplanation',
  inHub: 'hubExplanation',
  language: 'chooseTestLanguage',
  tabTracking: 'tabTrackingExplanation',
  cameraSupervision: 'cameraSupervisionExplanation'
})

export const getTraitsTargetGroup = (parentConfig) => {
  const configName = parentConfig.name.toLowerCase()

  if (configName.search('junior') !== -1) return TRAITS_TARGET_GROUPS.junior
  if (configName.search('professional') !== -1) return TRAITS_TARGET_GROUPS.professional
  if (configName.search('leadership') !== -1) return TRAITS_TARGET_GROUPS.leadership
  if (configName.search('sales') !== -1) return TRAITS_TARGET_GROUPS.sales

  return TRAITS_TARGET_GROUPS.professional
}

export const addAssessment = async (data, context, newProcessUuid) => {
  const reportModulesAndro = getReportModulesAndro(data)

  const payload = {
    assessmentName: data.assessmentName.trim(),
    assessmentDescription: data.assessmentDescription.trim(),
    copiedAssessmentUuid: data?.assessmentUuid,
    processUuid: data.processUuid || newProcessUuid,
    configUuid: data.configUuid,
    validFrom: data.validFrom ? formatDate(data.validFrom).androFormat : null,
    validUntil: data.validUntil ? formatDate(data.validUntil).androFormat : null,
    invitedDuration: data.invitedDuration,
    startedDuration: data.startedDuration,
    isSupervised: data.isSupervised,
    useChildItems: data.useChildItems,
    languageIds: data.languageIds,
    inHub: data.inHub,
    reportModules: reportModulesAndro,
    jobType: data.jobType,
    targetGroup: data.targetGroup,
    referenceAssessmentUuid: data?.referenceAssessmentUuid || null,
    weight: data.weight,
    useCameraSupervision: data.useCameraSupervision,
    useTabTracking: data.tabTracking,
    normNr: data.normNr || null,
    dimLogicNr: data.dimLogicNr || null,
    screenNrStart: data.screenNrStart || null,
    screenNrEnd: data.screenNrEnd || null
  }

  const responseData = await fetchData(payload, API_DATA.assessments.endPointAdd, context, 'testAdditionFailed')

  try {
    console.log('responseData from add ass: ', responseData)
    const update = {
      assessments: [responseData.response.data.assessment],
      processes: responseData.response.data.processes,
      usedConfigs: [responseData.response.data.usedConfig],
      dimensionLogics: responseData.response.data.dimensionLogics
    }
    updateEntities(update, context)
    return responseData
  } catch (e) {
    console.error(e)
    handleErrors(e)
  }
}

const handleErrors = (responseData) => {
  const ERROR_CODES = {
    1: 'Mssing assessment name',
    2: 'Missing process uuid',
    3: 'Missing config uuid',
    4: 'Missing language',
    5: 'Test could not be saved'
  }
  const errors = responseData?.response?.errorCodes
  if (errors?.length > 0) {
    errors.forEach((errorId) => {
      throw new Error(ERROR_CODES[errorId])
    })
  }
}

const getReportModulesAndro = (data) => {
  if (data.reportModulesAndro) return data.reportModulesAndro

  const reportModuleObj = {}

  const isDemo = checkDemoStatus(data.parentConfig, data.configUuid)
  if (isDemo) {
    reportModuleObj.demo = true
  }

  data.reportModulesArray.forEach((key) => {
    const androKey = REPORT_MODULES.find((reportModule) => reportModule.key === key)?.androKey
    return Object.assign(reportModuleObj, { [androKey]: true })
  })

  return reportModuleObj
}

export const getProcessFromUrlParams = (location, setData) => {
  const params = getParamsJson(location)

  if (params) {
    params.forEach((param) => {
      if (param.id === 'processUuid') {
        setState(setData, 'processUuid', param.value)
      }
    })
  }
}

export const clearReportModules = (setData) => setState(setData, 'reportModulesArray', [])

export const setTraitsTargetGroup = (parentConfig, setData) => {
  if (parentConfig?.name && isTraits(parentConfig.testType)) {
    const traitsTargetGroup = getTraitsTargetGroup(parentConfig)
    setState(setData, 'targetGroup', traitsTargetGroup)
  }
}

export const lazyLoadDimensionData = async (data, setData, context) => {
  const { parentConfig } = data
  const dataNotLoadedYet = parentConfig.availableConfigs[0].dimensions.length === 0

  if (dataNotLoadedYet) {
    const availableConfigIds = parentConfig.availableConfigs.map((c) => c.configUuid)
    const response = await fetchDimensionData(availableConfigIds, context)
    parentConfig.availableConfigs.forEach((config) => {
      config.dimensions = response.find((r) => r.uuid === config.configUuid).dimensions
    })
    const loadedDimensions = [...data.loadedDimensions, parentConfig.uuid]
    setState(setData, 'loadedDimensions', loadedDimensions)
  }
}

export const fetchDimensionData = async (uuids, context) => {
  const payload = { uuids: uuids }

  try {
    return await fetchData(payload, 'get_config_dimensions', context, null, false)
  } catch (e) {
    console.error(e)
  }
}

const isNonEmptyString = (str) => str.trim().length > 0
const isWithinMaxLength = (str, maxLength) => str.length <= maxLength
export const isValidName = (name, maxLength) =>
  isNonEmptyString(name) && isWithinMaxLength(name, maxLength) && !hasSpecialChars(name)

const validateProcess = (data) => {
  const newProcessNameIsValid = isValidName(data.processName, MAX_CHARS.processName)
  const existingProcessValid = !data.createNewProcess && Boolean(data.processUuid)
  const newProcessValid = data.createNewProcess && newProcessNameIsValid
  return existingProcessValid || newProcessValid
}

const validateAssessmentName = (data) => isValidName(data.assessmentName, MAX_CHARS.testName)

const validateReferenceTest = (data) => (isCrossTest(data.configVariant) ? Boolean(data.referenceAssessmentUuid) : true)

const validateTestDates = (data) => {
  const validFrom = data.validFrom || createDate(THE_PAST)
  const validUntil = data.validUntil || createDate(THE_FUTURE)
  return validFrom < validUntil
}

export const validateWizardData = (data) => {
  const processIsValid = validateProcess(data)
  const assessmentNameIsValid = validateAssessmentName(data)
  const refTestIsValid = validateReferenceTest(data)
  const testDatesValid = validateTestDates(data)

  return { processIsValid, assessmentNameIsValid, refTestIsValid, testDatesValid }
}

export const getWeightForNewTest = (data, completeDataSet) => {
  if (data.createNewProcess) {
    return isPotentials(data.configType) ? 100 : 0
  } else {
    const process = completeDataSet.processes.find((process) => process.processUuid === data.processUuid)
    const weightableTestCount = process?.relatedAssessments.filter((ass) => ass.producesNumericResult()).length
    return weightableTestCount === 0 && isPotentials(data.configType) ? 100 : 0
  }
}
