import ConfigVariantSelection from './ConfigVariantSelection'
import ConfigInfos from '../../../components/configInfos/ConfigInfos'
import ModuleOverview from '../../../components/moduleOverview/ModuleOverview'
import ScrollContainer from '../../../components/scrollContainer/ScrollContainer'
import { PageHeadline } from '../../../utils/elements/miscElements'
import styled from 'styled-components'
import { setState } from '../../../utils/helper/Helper'
import { useEffect } from 'react'
import useTranslate from '../../../utils/hooks/useTranslate'

const TestVariantForm = ({ data, setData }) => {
  const t = useTranslate()
  const availableConfigVariants = [
    data?.parentConfig?.availableConfigs?.find((config) => config.isStandard() && config.isLatest),
    data?.parentConfig?.availableConfigs?.find((config) => config.isQuickScan() && config.isLatest),
    data?.parentConfig?.availableConfigs?.find((config) => config.isCrossTest() && config.isLatest),
    data?.parentConfig?.availableConfigs?.find((config) => config.isDemo() && config.isLatest)
  ]

  const selectedConfig = availableConfigVariants.find((variant) => variant?.configUuid === data.configUuid)

  useEffect(() => {
    setState(setData, 'reportModulesArray', [])
  }, [setData])

  return (
    <>
      <PageHeadline>{t('chooseVariant')}</PageHeadline>
      <PageVariantContainer>
        <ScrollContainer>
          <ConfigVariantSelection parentConfig={data.parentConfig} setData={setData} data={data} />
        </ScrollContainer>

        {selectedConfig && (
          <ScrollContainer>
            <OuterContainer>
              <div style={{ maxWidth: '500px' }}>
                <h3 style={{ marginTop: 0 }}>Details</h3>
                <ConfigInfos parentConfig={data?.parentConfig} config={selectedConfig} position="addAssessmentWizard" />
              </div>
              <div>
                <h3 style={{ marginTop: 'var(--space-8)' }}>{t('testContent')}</h3>
                <ModuleOverview config={selectedConfig} />
              </div>
            </OuterContainer>
          </ScrollContainer>
        )}
      </PageVariantContainer>
    </>
  )
}

export default TestVariantForm

const OuterContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const PageVariantContainer = styled.div`
  display: grid;
  gap: var(--space-7);
  grid-template-columns: 280px 1fr;
  overflow: hidden;
  @media (max-width: 600px) {
    grid-template-columns: unset;
    gap: 0;
    .scroll-container:first-child {
      margin-bottom: 0;
    }
  }
`
