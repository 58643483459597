import ScrollContainer from '../../../components/scrollContainer/ScrollContainer'
import BillingAddressTile from '../../../components/tiles/BillingAddressTile'
import BillingHintTile from '../../../components/tiles/BillingHintTile'
import AddParticipantCheckInputTile from '../../../components/tiles/AddParticipantCheckInputTile'
import { useContext } from 'react'
import { AppContext } from '../../../utils/context/AppContext'
import { getMaximumCreditSum } from '../../../utils/helper/Helper'
import { PageHeadline } from '../../../utils/elements/miscElements'
import { TileContainer } from '../../../components/table/tableElements'
import useTranslate from '../../../utils/hooks/useTranslate'

const CheckInput = ({ data, assessments }) => {
  const t = useTranslate()
  const context = useContext(AppContext)
  const maximumCreditSum = getMaximumCreditSum(data.assessmentUuids, context, data.participantCount)

  return (
    <>
      <PageHeadline>{t('checkInputs')}</PageHeadline>
      <ScrollContainer>
        <TileContainer style={{ padding: 0 }}>
          <AddParticipantCheckInputTile {...{ data, assessments, maximumCreditSum }} />
          {maximumCreditSum > 0 && (
            <>
              <BillingAddressTile />
              <BillingHintTile />
            </>
          )}
        </TileContainer>
      </ScrollContainer>
    </>
  )
}

export default CheckInput
