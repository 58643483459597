import { TileHeadline, Tile } from '../table/tableElements'
import { InfoList } from '../../utils/elements/miscElements'
import BillingOverview from '../../pages/shop/subcomponents/BillingOverview'

const BillingAddressTile = () => (
  <Tile width="300px">
    <TileHeadline content="invoiceData" />
    <InfoList type="billing">
      <BillingOverview isOnConfirmationPage />
    </InfoList>
  </Tile>
)

export default BillingAddressTile
