import Cookies from 'universal-cookie'
import { useContext, useEffect } from 'react'
import { AppContext } from '../context/AppContext'

const useContactSettings = () => {
  const context = useContext(AppContext)

  useEffect(() => {
    setColorScheme(context.displayMode)
    setLanguage(context.language)
  }, [context.displayMode, context.language])

  useEffect(() => {
    const parentConfigs = context?.completeDataSet?.parentConfigs
    if (parentConfigs) {
      parentConfigs.forEach((pc) => pc.translate(context.language))
    }
  }, [context.language])
}

export default useContactSettings

const setColorScheme = (displayMode) => {
  const cookies = new Cookies()
  const osColorScheme = window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light'
  const scheme = displayMode === 'auto' ? osColorScheme : displayMode

  document.documentElement.setAttribute('data-theme', scheme)
  cookies.set('perseo-displayMode', displayMode, { path: '/' })
}

const setLanguage = (lang) => {
  const cookies = new Cookies()
  document.documentElement.lang = lang
  cookies.set('perseo-language', lang, { path: '/' })
}
