import { useContext, useEffect, useState } from 'react'
import {
  COUNTRIES,
  MODAL_TYPES,
  PAYMENT_METHOD_CREDIT_CARD,
  PAYMENT_OPTIONS,
  SELECT_OPTIONS
} from '../../../utils/constants/constants'
import { AppContext } from '../../../utils/context/AppContext'
import { getOptionByValue } from '../../../utils/helper/Helper'
import { ButtonPrimary, InfoElement, InfoList } from '../../../utils/elements/miscElements'
import BillingDataForm from './BillingDataForm'
import styled from 'styled-components'
import useTranslate from '../../../utils/hooks/useTranslate'
import { saveBillingFormData, validateBillingData } from '../shopUtils'

const BillingOverview = ({ orderData, isOnConfirmationPage }) => {
  const t = useTranslate()
  const context = useContext(AppContext)
  const completeDataSet = context.completeDataSet
  const billingData = completeDataSet.billing

  const initialData = {
    ...billingData,
    visibleErrors: []
  }
  const [formData, setFormData] = useState(initialData)

  const { billingDataValid } = validateBillingData(billingData)

  useEffect(() => {
    updateModalProps(context, formData, setFormData)
  }, [formData])

  const resetFormData = () => setFormData(initialData)

  const AddBillingInfoButton = () => (
    <ButtonPrimary
      content={'+ ' + t('add')}
      style={{ width: '120px' }}
      onClick={(e) => {
        e.preventDefault()
        resetFormData()
        updateModalProps(context, formData, setFormData)
        context.setModalOpen(true)
      }}
    />
  )

  const ChangeBillingInfoButon = () => (
    <button
      style={{ backgroundColor: 'transparent', display: 'flex', padding: 0 }}
      onClick={(e) => {
        e.preventDefault()
        resetFormData()
        updateModalProps(context, formData, setFormData)
        context.setModalOpen(true)
      }}>
      <span
        className="svg-icon icon-pen"
        style={{
          justifySelf: 'end',
          alignSelf: 'start',
          cursor: 'pointer'
        }}
      />
    </button>
  )

  const salutation = getOptionByValue(t(SELECT_OPTIONS.contactSalutations), billingData.billingSalutation).label || ''
  const billingContact = `${salutation} ${billingData.billingFirstName} ${billingData.billingLastName}`.trim()

  return (
    <>
      {!billingDataValid ? (
        <AddBillingInfoButton />
      ) : (
        <Container isOnConfirmationPage={isOnConfirmationPage}>
          <InfoList>
            <InfoElement
              label="address"
              value={
                <>
                  <ContentDisplay content={billingData.billingCustomer} />
                  <ContentDisplay content={billingData.billingLastName ? billingContact : ''} />
                  <ContentDisplay content={billingData.billingAddressAddition} />
                  <ContentDisplay content={billingData.billingStreet} />
                  <ContentDisplay content={`${billingData.billingZipCode} ${billingData.billingCity}`} />
                  <ContentDisplay content={getOptionByValue(COUNTRIES, billingData.billingCountry)?.label || ''} />
                </>
              }
            />
            <BillingCustomFields {...{ billingData }} />
            <BillingEmailAndPaymentMethod {...{ billingData, isOnConfirmationPage, orderData }} />
          </InfoList>
          {context.loggedInAsAdmin && (
            <div>
              <ChangeBillingInfoButon />
            </div>
          )}
        </Container>
      )}
    </>
  )
}

export default BillingOverview

const updateModalProps = (context, formData, setFormData) => {
  const { billingDataValid } = validateBillingData(formData)
  context.setModalProps({
    headline: 'invoiceData',
    content: <BillingDataForm formData={formData} setFormData={setFormData} showInModal />,
    type: MODAL_TYPES.payment,
    buttonPrimary: (
      <ButtonPrimary
        modalButton
        content="save"
        disabled={!billingDataValid}
        onClick={() => {
          saveBillingFormData({ data: formData, context: context })
          context.setModalOpen(false)
        }}
      />
    ),
    showCloseButton: true,
    hideFooter: false
  })
}

const ContentDisplay = ({ content }) => (
  <>
    {content && (
      <span>
        {content}
        <br />
      </span>
    )}
  </>
)

const BillingCustomFields = ({ billingData }) => {
  const showCustomFields = billingData.billingCustomField1Label || billingData.billingCustomField2Label
  if (!showCustomFields) return <></>
  return (
    <>
      {billingData.billingCustomField1Label && (
        <InfoElement label={billingData.billingCustomField1Label} value={billingData.billingCustomField1Content} />
      )}
      {billingData.billingCustomField2Label && (
        <InfoElement label={billingData.billingCustomField2Label} value={billingData.billingCustomField2Content} />
      )}
    </>
  )
}

const BillingEmailAndPaymentMethod = ({ billingData, isOnConfirmationPage, orderData }) => {
  const paymentMethod = PAYMENT_OPTIONS.find((opt) => opt.title === orderData?.paymentMethod)?.content || 'invoice'
  const payMethodLabel = <PayMethodLabel {...{ paymentMethod, orderData }} />

  return (
    <>
      <InfoElement label="sendByEmailTo" value={billingData.billingEmail} />
      {billingData.billingEmailCc && <InfoElement label="emailCc" value={billingData.billingEmailCc} />}
      {isOnConfirmationPage && <InfoElement label="paymentMethodLabel" value={payMethodLabel} />}
    </>
  )
}

const PayMethodLabel = ({ paymentMethod, orderData }) => {
  const t = useTranslate()
  const creditCard = orderData?.creditCard
  if (paymentMethod === PAYMENT_METHOD_CREDIT_CARD && creditCard) {
    return <CreditCardInfos {...{ creditCard }} />
  }
  return t(paymentMethod)
}

const CreditCardInfos = ({ creditCard }) => {
  const t = useTranslate()
  return (
    <>
      <span>{t('creditCard')}</span> (<span className="capitalizeFirstLetter">{creditCard.brand}</span>)
      <br />
      <span>
        <span style={{ letterSpacing: '1.5px' }}>•••• •••• ••••</span> {creditCard.last4}
      </span>
      <br />
      <span>
        {t('cardValidUntil')} {creditCard.exp_month}/{creditCard.exp_year}
      </span>
    </>
  )
}

const Container = styled.div`
  background-color: ${(props) => (props.isOnConfirmationPage ? 'none' : 'var(--box-background)')};
  padding: ${(props) => (props.isOnConfirmationPage ? '' : 'var(--space-5)')};
  border-radius: var(--bdr-2);
  display: grid;
  grid-template-columns: 1fr auto;
  & p:last-child {
    margin: 0;
  }
`
