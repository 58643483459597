import { Participant } from '../../entities/Participant'
import { API_DATA, STATUS_BAR_TYPES } from '../../utils/constants/constants'
import { fetchData, setStatusBar, updateEntities } from '../../utils/helper/Helper'
import { getUrlParam } from '../../utils/helper/urlParamsHelper'

export const changeReminderStatus = async (reminderNr, status, context) => {
  const payload = {
    reminderNr: reminderNr,
    active: status === 'active'
  }
  const responseData = await fetchData(payload, API_DATA.reminders.endPointChangeStatus, context)

  try {
    if (responseData.response.status === 1) {
      updateEntities(responseData.response.data, context)
      setStatusBar({
        controller: context.statusBarController,
        type: STATUS_BAR_TYPES.success,
        text: status === 'active' ? 'reminderSuccessfullyActivated' : 'reminderSuccessfullyDeactivated',
        setVisible: true
      })
    }
  } catch (e) {
    console.error(e)
  }
}

export const getDummyParticipants = () => {
  return [
    new Participant({
      pNr: 'dummy_1',
      pFirstName: 'Max',
      pLastName: 'Mustermann',
      pGender: 'm',
      pMail: 'max.mustermann@email.de'
    }),
    new Participant({
      pNr: 'dummy_2',
      pFirstName: 'Martina',
      pLastName: 'Musterfrau',
      pGender: 'f',
      pMail: 'martina.musterfrau@email.de'
    })
  ]
}

export const getReminderNrFromUrl = (location) => {
  const params = getUrlParam(location)
  return params.reminderNr
}

export const getReminderRule = (days, triggerType, t) => {
  return `${days} ${t('days')} ${t(triggerType)}`
}

export const duplicateReminder = async (reminderNr, context) => {
  const payload = { reminderNr: reminderNr }
  const responseData = await fetchData(payload, API_DATA.reminders.endPointDuplicate, context)

  try {
    if (responseData.response.status === 1) {
      updateEntities(responseData.response.data, context)
      setStatusBar({
        controller: context.statusBarController,
        type: STATUS_BAR_TYPES.success,
        text: 'reminderSuccessfullyDuplicated',
        setVisible: true
      })
    }
  } catch (e) {
    console.error(e)
  }
}
