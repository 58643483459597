import PageGroup from '../../components/pageGroup/PageGroup'
import AddToTestCheckInputPage from './subComponents/AddToTestCheckInputPage'
import AddPtSuccessPage from '../sharedWizardElements/addPtSuccessPage'
import SelectProcessAndAssessmentsPage from '../../pages/selectProcessAndAssessmentsPage'
import React, { useState, useEffect, useContext } from 'react'
import { useLocation } from 'react-router-dom'
import { SUCCESS_PAGE_TYPES, STATUS_BAR_TYPES } from '../../utils/constants/constants'
import { AppContext } from '../../utils/context/AppContext'
import { ButtonPrimary, ButtonSecondary } from '../../utils/elements/miscElements'
import { addParticipantsToTest, getSelectedParticipants } from './addToTestUtils'
import { PAGES } from '../../utils/constants/pages'
import { getMaximumCreditSum, setStatusBar } from '../../utils/helper/Helper'
import { ButtonAfterPtAdd, ButtonDownloadAccessData } from '../sharedWizardElements/addPtWizardButtons'
import useTranslate from '../../utils/hooks/useTranslate'
import useRedirect from '../../utils/hooks/useRedirect'
import { validateBillingData } from '../../pages/shop/shopUtils'

const AddParticipantToAnotherTest = () => {
  const t = useTranslate()
  const location = useLocation()
  const context = useContext(AppContext)
  const redirect = useRedirect()

  const completeDataSet = context.completeDataSet
  const { participants } = completeDataSet

  const selectedParticipants = getSelectedParticipants(participants, location, context.completeDataSet.pts)
  const selectedpNrs = selectedParticipants.map((p) => p.pNr)

  useEffect(() => {
    if (!selectedParticipants.length) {
      redirect(PAGES.dashboard)
    }
  }, [selectedParticipants])

  const [activePageId, setActivePageId] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const [newData, setNewData] = useState({})
  const [data, setData] = useState({
    processUuid: null,
    assessmentUuids: [],
    participantList: selectedpNrs
  })

  const maximumCreditSum = getMaximumCreditSum(data.assessmentUuids, context, data.participantList.length)
  const { billingDataValid } = validateBillingData(context.completeDataSet.billing)

  const handleAddToTest = () => {
    setIsLoading(true)
    addParticipantsToTest(data, context).then((newData) => {
      setNewData(newData)
      setActivePageId(activePageId + 1)
      setStatusBar({
        controller: context.statusBarController,
        type: STATUS_BAR_TYPES.success,
        text: 'participantAddedSuccessfully'
      })
    })
  }

  const optionDescriptions = {
    process: t('selectProcessToAddParticipants'),
    assessments: t('addParticipantsToMultipleTests')
  }

  const pages = {
    selectProcessAndAssessmentsPage: {
      content: <SelectProcessAndAssessmentsPage {...{ data, setData, selectedParticipants, optionDescriptions }} />,
      title: t('processesAndTests'),
      buttonSecondary: <ButtonSecondary content="cancel" onClick={() => redirect(PAGES.participants)} />,
      buttonPrimary: (
        <ButtonPrimary
          content="next"
          onClick={() => setActivePageId(activePageId + 1)}
          disabled={data.assessmentUuids.length === 0}
        />
      )
    },
    addToTestCheckInputPage: {
      content: (
        <AddToTestCheckInputPage
          {...{
            data,
            selectedParticipants,
            maximumCreditSum
          }}
        />
      ),
      title: t('checkInputs'),
      buttonSecondary: <ButtonSecondary content="back" onClick={() => setActivePageId(activePageId - 1)} />,
      buttonPrimary: (
        <ButtonPrimary
          disabled={(maximumCreditSum > 0 && !billingDataValid) || isLoading}
          content="add"
          onClick={handleAddToTest}
        />
      )
    },
    addPtSuccessPage: {
      content: <AddPtSuccessPage newData={newData} type={SUCCESS_PAGE_TYPES.ptsOnly} />,
      hidePageIndicator: true,
      largeButtons: true,
      buttonSecondary: <ButtonDownloadAccessData {...{ newData }} />,
      buttonPrimary: <ButtonAfterPtAdd {...{ newData, data }} />
    }
  }

  return (
    <PageGroup
      pages={[pages.selectProcessAndAssessmentsPage, pages.addToTestCheckInputPage, pages.addPtSuccessPage]}
      activePageId={activePageId}
    />
  )
}

export default AddParticipantToAnotherTest
