import { PtInvitationTemplate } from '../../../entities/PtInvitationTemplate'
import { DEFAULT_TEMPLATE_NR, EMAIL_TEXTS, SALUTATION_TYPES } from '../../../utils/constants/constants'
import { getLoggedInContact } from '../../../utils/helper/Helper'

export const getAllTemplates = (completeDataSet, t) => {
  return [...getDefaultTemplates(completeDataSet, t), ...completeDataSet.ptInvitationTemplates].map((t) => ({
    ...t,
    isDirty: false
  }))
}

export const getDefaultTemplates = (completeDataSet, t) => {
  const customer = completeDataSet.customer
  const { customerName } = customer
  const contact = getLoggedInContact(completeDataSet)

  return [
    new PtInvitationTemplate({
      publicKey: DEFAULT_TEMPLATE_NR,
      title: t('defaultInvitation'),
      createdBy: 'PERSEO',
      disabled: true,
      templateData: {
        salutationType: SALUTATION_TYPES.politeLastName,
        subject: t(EMAIL_TEXTS.invitationSubject),
        topText: t('defaultTopTextInvitation'),
        bottomText: t('defaultBottomText', customerName),
        footerLeft: customer.infoHtml,
        footerCenter: contact.infoHtml
      }
    }),
    new PtInvitationTemplate({
      publicKey: 'default_invitation_casual',
      title: t('defaultInvitationCasual'),
      createdBy: 'PERSEO',
      disabled: true,
      contact: contact,
      customer: customer,
      templateData: {
        salutationType: SALUTATION_TYPES.casualFirstName,
        subject: t(EMAIL_TEXTS.invitationSubject),
        topText: t('defaultTopTextInvitationCasual'),
        bottomText: t('defaultBottomTextCasual', customerName),
        footerLeft: customer.infoHtml,
        footerCenter: contact.infoHtml
      }
    }),
    new PtInvitationTemplate({
      publicKey: 'default_reminder',
      title: t('defaultReminder'),
      createdBy: 'PERSEO',
      disabled: true,
      contact: contact,
      customer: customer,
      templateData: {
        salutationType: SALUTATION_TYPES.politeLastName,
        subject: t(EMAIL_TEXTS.reminderSubject),
        topText: t('defaultTopTextReminder'),
        bottomText: t('defaultBottomText', customerName),
        footerLeft: customer.infoHtml,
        footerCenter: contact.infoHtml
      }
    }),
    new PtInvitationTemplate({
      publicKey: 'default_reminder_casual',
      title: t('defaultReminderCasual'),
      createdBy: 'PERSEO',
      disabled: true,
      contact: contact,
      customer: customer,
      templateData: {
        salutationType: SALUTATION_TYPES.casualFirstName,
        subject: t(EMAIL_TEXTS.reminderSubjectCasual),
        topText: t('defaultTopTextReminderCasual'),
        bottomText: t('defaultBottomTextCasual', customerName),
        footerLeft: customer.infoHtml,
        footerCenter: contact.infoHtml
      }
    })
  ]
}
