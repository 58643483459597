import styled from 'styled-components'

export const InnerContainer = styled.div.attrs({
  className: 'options-inner-container'
})`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 350px;
`

export const OuterContainer = styled.div`
  display: flex;
  gap: var(--space-5);
  @media screen and (max-width: 600px) {
    flex-direction: column;
    ${InnerContainer} {
      display: grid;
      grid-template-columns: 1fr auto;
      gap: var(--space-3);
      max-width: unset;
    }
    .custom-checkmark {
      width: 32px;
      height: 32px;
    }
  }
`

export const Title = styled.span.attrs({
  className: 'language-option-title'
})`
  color: var(--text-color-primary);
  font-size: var(--fs-7);
  text-align: center;
  line-height: var(--lh-4);
`

export const Icon = styled.span`
  width: var(--icon-3);
  height: var(--icon-3);
  justify-self: center;
  margin: 0 auto;
`

export const Description = styled.span`
  text-align: center;
  line-height: var(--lh-3);
  color: var(--text-color-secondary);
`
