import React, { useContext, useEffect } from 'react'
import { getFormattedPrice, getFormattedNumber, setState, isInteger } from '../../../utils/helper/Helper'
import { CreditButtonContainer, PriceSummaryContainer, PriceEntry, PriceTag } from '../shopElements'
import { NUMBER_INPUT_ALLOWED_KEYS } from '../../../utils/constants/constants'
import { getPriceTotal } from '../shopUtils'
import styled from 'styled-components'
import { AppContext } from '../../../utils/context/AppContext'
import useTranslate from '../../../utils/hooks/useTranslate'

const PriceSummary = ({ orderDataState }) => {
  const t = useTranslate()
  const context = useContext(AppContext)
  const creditPrices = context.completeDataSet.customer.creditPrices
  const { orderData, setOrderData } = orderDataState

  const handleNumberCreditChange = (e) => {
    const val = e.target.value
    const intVal = parseInt(val.split('.').join(''))

    if (intVal > 9999 || !isInteger(intVal)) return
    setState(setOrderData, 'numberCredits', intVal || 0)
  }

  useEffect(() => {
    const subTotal = getPriceTotal(orderData.numberCredits, creditPrices)
    const discount = subTotal * orderData.discountRate * -1
    const total = subTotal + discount

    setOrderData((prevData) => ({
      ...prevData,
      subTotal: subTotal,
      discount: discount,
      total: total
    }))
  }, [orderData.numberCredits, orderData.discountRate, setOrderData, creditPrices])

  const handleKeydown = (e) => {
    if (e.key === ',' || e.key === '.' || !NUMBER_INPUT_ALLOWED_KEYS.includes(e.key)) e.preventDefault()
  }

  return (
    <>
      <div />
      <CreditButtonContainer>
        <PriceSummaryContainer>
          <PriceEntry style={{ marginBottom: '-4px', alignItems: 'center' }}>
            <span>{t('creditCount')}:</span>
            <NumberCreditsInput
              id="creditsCount"
              type="text"
              onKeyDown={handleKeydown}
              onChange={handleNumberCreditChange}
              value={getFormattedNumber(orderData.numberCredits, context.language)}
            />
          </PriceEntry>
          {orderData.discountRate > 0 && (
            <>
              <PriceEntry>
                <span>{t('subtotal')}:</span>
                <PriceTag>{getFormattedPrice(orderData.subTotal, context.language)}</PriceTag>
              </PriceEntry>
              <PriceEntry>
                <span style={{ color: 'var(--price-tag-discount-color)' }}>
                  {t('minus')} {orderData.discountRate * 100} % {t('discount')}:
                </span>
                <PriceTag red>{getFormattedPrice(orderData.discount, context.language)}</PriceTag>
              </PriceEntry>
            </>
          )}
          <PriceEntry>
            <span style={{ fontWeight: '600' }}>{t('netAmount')}:</span>
            <PriceTag highlight>{getFormattedPrice(orderData.total, context.language)}</PriceTag>
          </PriceEntry>
        </PriceSummaryContainer>
      </CreditButtonContainer>
    </>
  )
}
export default PriceSummary

const NumberCreditsInput = styled.input`
  width: 80px;
  padding: var(--space-3) !important;
  text-align: right;
`
