import React from 'react'
import DataTable from '../../../components/table/DataTable'
import ScrollContainer from '../../../components/scrollContainer/ScrollContainer'
import { PageHeadline, SubHeadline } from '../../../utils/elements/miscElements'
import { getConfigStyle, getTestTypeName } from '../../../utils/helper/Helper'
import { ENTITIES, FILTER_TYPES } from '../../../utils/constants/constants'
import useTranslate from '../../../utils/hooks/useTranslate'
import { getSpacerCol } from '../../../components/table/tableCols/tableCols'

const SelectParentConfigForm = ({ parentConfigs, selectedRowIds, setData }) => {
  const t = useTranslate()
  const columns = React.useMemo(getCols, [])

  const parentConfigsFiltered = getFilteredParentConfigs(parentConfigs)
  const hasCustomConfigs = parentConfigsFiltered.find((pc) => pc.status === 'Custom')

  const onSelectedRowsChange = (rowData, rowIds) =>
    setData((prevData) => ({
      ...prevData,
      parentConfig: rowData[0],
      selectedRowIds: rowIds
    }))

  return (
    <>
      <PageHeadline>{t('chooseTest')}</PageHeadline>
      <ScrollContainer>
        <SubHeadline style={{ fontSize: 'var(--fs-5)' }}>{t('findPreconfiguredTest')}</SubHeadline>
        <DataTable
          data={parentConfigsFiltered}
          columns={columns}
          showRowSelection
          selectedRows={selectedRowIds}
          onSelectedRowsChange={onSelectedRowsChange}
          hideIndeterminateCheckbox
          selectSingleRow
          showFilters
          tableName={ENTITIES.configs}
          hideArchiveButton
          checkboxType="radio"
          hideBulkMenu
          hideResultCount
          showCustomConfigFilter={hasCustomConfigs}
        />
      </ScrollContainer>
    </>
  )
}

export default SelectParentConfigForm

const getCols = () => [
  {
    Header: 'freeTextFilter',
    id: 'freeText',
    accessor: (row) => row.name + row.careerLevel.join() + row.specificJobs.join(),
    filterOptions: { type: FILTER_TYPES.textInput },
    canExport: false,
    show: false
  },
  {
    Header: 'testName',
    accessor: 'name',
    id: 'testName',
    filterOptions: { showInFieldSelector: true }
  },
  {
    Header: 'testType',
    accessor: 'testTypeName',
    id: 'testTypeName',
    filterOptions: { showInFieldSelector: true },
    Cell: ({ row }) => {
      const t = useTranslate()
      const configStyle = getConfigStyle(row.original.availableConfigs[0])
      const iconStyle = {
        gridRow: '1 / 3',
        width: '18px',
        height: '18px',
        backgroundColor: configStyle.color,
        marginRight: 'var(--space-3)'
      }

      return (
        <div style={{ display: 'inline-flex', alignItems: 'center', verticalAlign: 'middle' }}>
          <span className={configStyle.icon} style={iconStyle} />
          <span>{t(row.original.testTypeName)}</span>
        </div>
      )
    }
  },
  {
    Header: 'careerLevels',
    accessor: 'careerLevel',
    id: 'careerLevels',
    filterOptions: { showInFieldSelector: true },
    Cell: ({ value }) => (
      <div>
        {value.map((level, i) => (
          <span key={level + i}>
            {level}
            {i + 1 < value.length ? ', ' : ''}{' '}
          </span>
        ))}
      </div>
    )
  },
  getSpacerCol(),
  {
    Header: 'Job Gruppe',
    accessor: 'jobGroup',
    show: false,
    id: 'jobGroup',
    filterOptions: { showInFieldSelector: true },
    Cell: ({ value }) => (
      <div>
        {value.map((group, i) => (
          <span key={group + i}>
            {group}
            {i + 1 < value.length ? ', ' : ''}{' '}
          </span>
        ))}
      </div>
    )
  },
  {
    Header: 'jobProfiles',
    accessor: 'specificJobs',
    id: 'specificJobs',
    filterOptions: { showInFieldSelector: true },
    Cell: ({ value }) => (
      <div>
        {value.map((job, i) => (
          <span key={job + i}>
            {job}
            {i + 1 < value.length ? ', ' : ''}{' '}
          </span>
        ))}
      </div>
    ),
    show: false
  },
  {
    Header: 'Status',
    accessor: 'status',
    show: false,
    id: 'status'
  },
  {
    Header: 'availableLanguages',
    accessor: (row) => row.availableLanguages.map((lang) => lang.label),
    show: false,
    id: 'availableLanguages',
    filterOptions: { showInFieldSelector: true, label: 'testLanguage' }
  }
]

const getFilteredParentConfigs = (parentConfigs) => {
  return parentConfigs
    .filter((pc) => pc.availableConfigs !== null && pc?.availableConfigs.find((config) => config.isLatest))
    .map((pc) => ({ ...pc, testTypeName: getTestTypeName(pc.testType) }))
    .sort((a, b) => a.name.localeCompare(b.name))
}
