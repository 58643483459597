import RowActions from '../components/table/RowActions'
import { CUSTOMER_VISIBILITY_STATUS, ENTITIES } from '../utils/constants/constants'
import { createDate } from '../utils/helper/dateTimeHelper'
import { PtInvitationTemplate } from './PtInvitationTemplate'

export class Reminder {
  constructor({ ...props }) {
    this.entity = 'Reminder'
    this.active = props.active ? true : false
    this.assessmentUuids = props.assessmentUuids || []
    this.createdBy = props.createdBy || ''
    this.days = props.days || 0
    this.created = createDate(props.created)
    this.reminderNr = props.reminderNr || ''
    this.replyToName = props?.templateData?.replyToName || ''
    this.replyToEmail = props?.templateData?.replyToEmail || ''
    this.template = new PtInvitationTemplate(props) || {}
    this.triggerType = props.triggerType || undefined
    this.actions = <RowActions row={this} entity={ENTITIES.reminders} />
    this.visibility = props.visibility || CUSTOMER_VISIBILITY_STATUS.visible
    this.title = props.title || ''
  }
}
