import { PAGES } from '../../utils/constants/pages'

export const getMainNavData = () => [
  {
    label: 'Dashboard',
    page: PAGES.dashboard,
    icon: 'icon-dashboard',
    hasSpacer: true,
    className: 'dashboard'
  },
  {
    label: 'processes',
    page: PAGES.processes,
    icon: 'icon-folder',
    className: 'processes'
  },
  {
    label: 'Tests',
    page: PAGES.assessments,
    icon: 'icon-tests',
    className: 'tests'
  },
  {
    label: 'participants',
    page: PAGES.participants,
    icon: 'icon-participants-light',
    className: 'participants'
  },
  {
    label: 'pts',
    page: PAGES.pts,
    icon: 'icon-pt',
    hasSpacer: true,
    className: 'pts'
  },
  {
    label: 'bookings',
    page: PAGES.credits,
    icon: 'icon-coin-light',
    className: 'bookings'
  },
  {
    label: 'Credit Shop',
    page: PAGES.shop,
    icon: 'icon-shop',
    hasSpacer: true,
    className: 'shop'
  },
  {
    label: 'emails',
    page: PAGES.emails,
    icon: 'icon-email-light',
    hasSpacer: true,
    className: 'emails'
  },
  {
    label: 'help',
    page: PAGES.help,
    icon: 'icon-help-light',
    className: 'help'
  },
  {
    label: 'Support',
    page: PAGES.support,
    icon: 'icon-support-light',
    className: 'support'
  },
  {
    label: 'Feedback',
    page: PAGES.feedback,
    icon: 'icon-feedback',
    className: 'feedback'
  }
]
