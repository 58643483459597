import React, { useContext } from 'react'
import DataTable from '../../components/table/DataTable'
import { ContentContainer } from '../../components/table/tableElements'
import { AppContext } from '../../utils/context/AppContext'
import ScrollContainer from '../../components/scrollContainer/ScrollContainer'
import { ENTITIES, FIELD_CATEGORIES, FILTER_TYPES } from '../../utils/constants/constants'
import {
  getActionsCol,
  getCreatedByCol,
  getDateCreatedCol,
  getSpacerCol,
  getVisibilityCol
} from '../../components/table/tableCols/tableCols'
import useTranslate from '../../utils/hooks/useTranslate'
import { getParticipantNumberCol } from '../dashboard/dashboardUtils'
import { getTruncatedList } from '../../utils/helper/Helper'

const Emails = () => {
  const context = useContext(AppContext)
  const completeDataSet = context.completeDataSet
  const { participantMailLogs } = completeDataSet
  const t = useTranslate()
  const columns = getCols(t)

  return (
    <ScrollContainer type="main">
      <ContentContainer>
        <DataTable
          data={participantMailLogs}
          columns={columns}
          showRowSelection
          tableName={ENTITIES.participantMailLogs}
          showFilters
          showColumnController
          showAddButton
        />
      </ContentContainer>
    </ScrollContainer>
  )
}

export default Emails

const getCols = (t) => {
  return [
    {
      Header: 'freeTextFilter',
      id: 'freeText',
      accessor: (row) =>
        (row.relatedParticipant.pMail || '') +
        row.relatedParticipant.pNr +
        row.subject +
        (row.relatedParticipant.pFullName || '') +
        row.assessmentNames,
      filter: 'like',
      filterOptions: { type: FILTER_TYPES.textInput },
      canExport: false,
      show: false
    },
    getParticipantNumberCol(),
    {
      Header: 'emailAddress',
      id: 'email',
      accessor: (row) => row.mailAddress || '',
      filterOptions: { showInFieldSelector: true, category: FIELD_CATEGORIES.participant },
      canExport: true
    },
    {
      Header: 'subject',
      id: 'subject',
      accessor: (row) => row.template.subject,
      filterOptions: { showInFieldSelector: true, category: FIELD_CATEGORIES.email },
      canExport: true
    },
    {
      Header: 'emailType',
      id: 'emailType',
      accessor: 'emailType',
      filterOptions: { showInFieldSelector: true, category: FIELD_CATEGORIES.email },
      canExport: true,
      Cell: ({ value }) => {
        const t = useTranslate()
        return <div>{t(value)}</div>
      }
    },
    {
      Header: 'Tests',
      id: 'assessmentNames',
      canExport: true,
      show: true,
      filterOptions: { showInFieldSelector: true, category: FIELD_CATEGORIES.test, label: 'relatedTest' },
      accessor: (row) => getTruncatedList(row.assessmentNames, t, 1)
    },
    getCreatedByCol('mailCreatedBy', FIELD_CATEGORIES.email, 'sentBy'),
    getDateCreatedCol('mailCreated', FIELD_CATEGORIES.email, 'sendTime'),
    getSpacerCol(),
    getActionsCol(),
    getVisibilityCol(FIELD_CATEGORIES.email)
  ]
}
