import styled from 'styled-components'
import {
  BtnUnderline,
  BtnBold,
  BtnItalic,
  BtnBulletList,
  BtnLink,
  Editor,
  EditorProvider,
  BtnNumberedList
} from 'react-simple-wysiwyg'
import { useState } from 'react'
import { stripAllTags } from '../../../utils/helper/Helper'

const ScreenEditor = ({ screen, dispatch, language }) => {
  const [contentIsFocused, setContentIsFocused] = useState(false)

  return (
    <ElektryonWrapper>
      <EditorProvider>
        <Header contentIsFocused={contentIsFocused} />
        <Content
          content={screen.content}
          dispatch={dispatch}
          language={language}
          contentIsFocused={contentIsFocused}
          setContentIsFocused={setContentIsFocused}
        />
        <Footer />
      </EditorProvider>
    </ElektryonWrapper>
  )
}

export default ScreenEditor

export const ElektryonWrapper = styled.div`
  border: 1px solid var(--border-grey);
  border-radius: var(--bdr-2);
  overflow: hidden;

  ul li {
    list-style-type: disc;
  }
  ol li {
    list-style-type: decimal;
  }
  ol,
  ul {
    padding-inline-start: 1em;
  }
  &.smaller {
    border: 1px solid var(--table-in-tab-border-color);
    input {
      font-size: var(--fs-6);
    }
    p,
    li {
      font-size: var(--fs-3);
    }
  }
`

const Header = ({ contentIsFocused }) => {
  return (
    <HeaderFooterBar isHeader>
      <HeaderFooterContentWrapper>
        <BackButton>
          <span className="svg-icon icon-elektryon-button" />
        </BackButton>

        <div className="toolbar-wrapper">
          <CustomToolbar disabled={!contentIsFocused} />
        </div>

        <MenuIcon className="svg-icon icon-menu" />
      </HeaderFooterContentWrapper>
    </HeaderFooterBar>
  )
}

const CustomToolbar = ({ disabled }) => (
  <div className={`toolbar ${disabled ? 'disabled' : ''}`}>
    <BtnBold />
    <BtnItalic />
    <BtnUnderline />
    <BtnBulletList />
    <BtnNumberedList />
    <BtnLink />
  </div>
)

const Content = ({ content, dispatch, language, contentIsFocused, setContentIsFocused }) => {
  const onChange = (e, field) => {
    dispatch({
      type: 'updated_screen_content',
      field: field,
      content: e.target.value,
      language: language
    })
  }
  const headlineEmpty = content?.[language]?.headline.length === 0
  const bodyEmpty = stripAllTags(content?.[language]?.body).length === 0

  return (
    <ElektryonContentWrapper>
      <ElektryonContentContainer>
        <ElektryonH1
          value={content?.[language]?.headline}
          onChange={(e) => onChange(e, 'headline')}
          className={headlineEmpty ? 'has-error' : ''}
        />
        <Separator />
        <ElektryonContent
          onFocus={() => setContentIsFocused(true)}
          onBlur={() => setContentIsFocused(false)}
          className={`${contentIsFocused ? 'is-focused' : ''} ${bodyEmpty ? 'has-error' : ''}`}>
          <Editor value={content?.[language]?.body} onChange={(e) => onChange(e, 'body')} />
        </ElektryonContent>
      </ElektryonContentContainer>
    </ElektryonContentWrapper>
  )
}

const Footer = () => {
  return (
    <HeaderFooterBar isFooter>
      <HeaderFooterContentWrapper>
        <Logo className="svg-icon perseo-logo" />
        <ContinueButtonContainer>
          <ContinueButton>
            <span className="svg-icon icon-elektryon-button" />
          </ContinueButton>
        </ContinueButtonContainer>
      </HeaderFooterContentWrapper>
    </HeaderFooterBar>
  )
}

const MenuIcon = styled.span`
  background-color: var(--elektryon-color-ui-button);
  width: 18px;
  height: 18px;
`

export const ElektryonContentWrapper = styled.div`
  display: flex;
  justify-content: center;
  * {
    line-height: var(--lh-3);
  }
`

export const ElektryonContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: var(--space-8) 0;
  width: 400px;
  max-width: 90%;
  .rsw-editor {
    border: none;
    border-radius: 0;
  }
  .rsw-ce {
    padding: 0;
  }
`

export const Separator = styled.hr`
  border: none;
  border-bottom: 1px solid var(--elektryon-header-footer-border);
  margin-bottom: 1.5rem;
`

export const ElektryonH1 = styled.input`
  font-family: 'Open Sans';
  font-weight: 400;
  font-size: var(--fs-7);
  margin-top: 0;
  margin-bottom: 1.5rem;
  border: 1px solid transparent;
  margin-left: -1px;
  color: var(--elektryon-h1-color);
  text-align: left;
  background-color: transparent;
  border-radius: var(--bdr-2);
  &:hover {
    border-color: var(--border-grey);
  }
  &:focus {
    border-color: var(--color-focus-outline);
  }
`

const ElektryonContent = styled.div`
  border: 1px solid transparent;
  border-radius: var(--bdr-2);
  padding: 1px 2px;
  &:hover {
    border-color: var(--border-grey);
  }
  &.is-focused {
    border-color: var(--color-focus-outline);
  }
`

const UiButton = styled.div`
  border-radius: 50%;
  background-color: var(--elektryon-color-ui-button);
  display: flex;
  align-items: center;
  justify-content: center;
  span {
    background-color: #fff;
    mask-size: auto;
  }
`

const BackButton = styled(UiButton)`
  transform: scaleX(-1);
  height: 30px;
  width: 30px;
  flex-shrink: 0;
  span {
    width: 12px;
    height: 12px;
  }
`

const ContinueButton = styled(UiButton)`
  height: 60px;
  width: 60px;
  span {
    width: 25px;
    height: 25px;
  }
  &:after {
    content: '';
    position: absolute;
    height: 82px;
    width: 82px;
    border: 1px solid var(--elektryon-header-footer-border);
    background: var(--elektryon-header-footer-bg);
    left: -11px;
    top: -11px;
    border-radius: 50%;
    z-index: -2;
  }
`

const ContinueButtonContainer = styled.div`
  position: relative;
  margin-bottom: 30px;
  z-index: 1;
  &:before {
    content: '';
    position: absolute;
    height: 69px;
    width: 150%;
    background: var(--elektryon-header-footer-bg);
    left: -25%;
    top: 11px;
    z-index: -1;
  }
`

const HeaderFooterContentWrapper = styled.div`
  width: 90%;
  height: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
`

const Logo = styled.span`
  background-color: var(--elektryon-perseo-logo-color);
  height: 35px;
  width: 90px;
  transform: translateY(-4px);
`

const HeaderFooterBar = styled.div`
  height: ${(props) => (props.isFooter ? '69px' : '70px')};
  background-color: var(--elektryon-header-footer-bg);
  border-color: var(--elektryon-header-footer-border);
  border-style: solid;
  border-width: 0px;
  border-top-width: ${(props) => (props.isFooter ? '1px' : 0)};
  border-bottom-width: ${(props) => (props.isHeader ? '1px' : 0)};
  .toolbar-wrapper {
    width: 400px;
    transform: translateX(-4px);
  }
  .toolbar {
    display: flex;
    align-items: center;
    border: 1px solid var(--elektryon-header-footer-border);
    border-radius: var(--bdr-2);
    overflow: hidden;
    height: 32px;
    width: max-content;
    &.disabled button {
      color: var(--text-color-secondary);
      pointer-events: none;
    }
    button {
      color: var(--text-color-primary);
      height: 100%;
      display: inline-block;
      line-height: normal;
      width: var(--space-7);
      position: relative;
      &:first-child {
        margin-left: 4px;
      }
      &:last-child {
        margin-right: 4px;
      }
      &:nth-child(3),
      &:nth-child(5) {
        margin-right: 8px;
        &:after {
          content: '';
          border-right: 1px solid var(--border-grey);
          height: 32px;
          display: block;
          position: absolute;
          top: 0;
          right: -4px;
        }
      }
      span {
        line-height: normal;
      }
      &:hover {
        background: var(--button-hover-color);
      }
    }
  }
`
