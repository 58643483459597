import {
  WizardForm,
  Label,
  ButtonPrimary,
  Modalfooter,
  selectStyles,
  ErrorMessage
} from '../../utils/elements/miscElements'
import { API_DATA, FIELD_LABELS, FORM_ERRORS } from '../../utils/constants/constants'
import {
  setStatusBar,
  setState,
  getOptionByValue,
  isGermanZipCode,
  isOnlyNumbers,
  getCityFromZipCode,
  fetchData
} from '../../utils/helper/Helper'
import { useState, useContext, useRef } from 'react'
import { AppContext } from '../../utils/context/AppContext'

import { COUNTRIES, STATUS_BAR_TYPES } from '../../utils/constants/constants'
import Select from 'react-select'
import ScrollContainer from '../scrollContainer/ScrollContainer'
import useTranslate from '../../utils/hooks/useTranslate'
import { Customer } from '../../entities/Customer'

const CustomerSettingsComponent = () => {
  const context = useContext(AppContext)
  const completeDataSet = context.completeDataSet
  const customer = completeDataSet.customer
  const submitButtonRef = useRef(null)
  const t = useTranslate()

  const [data, setData] = useState({
    customerName: customer.customerName,
    customerCity: customer.customerCity,
    customerCountry: customer.customerCountry,
    customerStreet: customer.customerStreet,
    customerZipCode: customer.customerZipCode
  })

  const [showErrors, setShowErrors] = useState(false)

  const nameEmpty = data.customerName.length === 0

  const handleZipCodeChange = (e) => {
    const zipCode = e.target.value
    if (isOnlyNumbers(zipCode)) {
      setState(setData, 'customerZipCode', zipCode)
    }

    if (isGermanZipCode(zipCode)) {
      getCityFromZipCode(zipCode).then((city) => {
        if (city) {
          setData((prev) => ({
            ...prev,
            customerCity: city,
            customerCountry: 'DE'
          }))
        }
      })
    }
  }

  const content = (
    <ScrollContainer type="settings">
      <WizardForm style={{ maxWidth: '400px' }}>
        <div style={{ display: 'grid' }}>
          <Label key="customerName">
            <span>{t(FIELD_LABELS.personalName)} *</span>
            <input
              type="text"
              value={data.customerName}
              onChange={(e) => setState(setData, 'customerName', e.target.value)}
              onBlur={() => setShowErrors(true)}
              disabled={!context.loggedInAsAdmin}
              className={nameEmpty && showErrors ? 'has-error' : ''}
            />
          </Label>
          <ErrorMessage visible={nameEmpty && showErrors}>{t(FORM_ERRORS.nameEmpty)}</ErrorMessage>
        </div>
        <Label key="customerStreet">
          <span>{t(FIELD_LABELS.street)}</span>
          <input
            type="text"
            value={data.customerStreet}
            onChange={(e) => setState(setData, 'customerStreet', e.target.value)}
            disabled={!context.loggedInAsAdmin}
          />
        </Label>
        <div className="zipcode-city-wrapper">
          <Label key="customerZipCode">
            <span>{t(FIELD_LABELS.zipCode)}</span>
            <input
              type="text"
              value={data.customerZipCode}
              onChange={handleZipCodeChange}
              disabled={!context.loggedInAsAdmin}
            />
          </Label>
          <Label key="customerCity">
            <span>{t(FIELD_LABELS.city)}</span>
            <input
              type="text"
              value={data.customerCity}
              onChange={(e) => setState(setData, 'customerCity', e.target.value)}
              disabled={!context.loggedInAsAdmin}
            />
          </Label>
        </div>
        <Label key="customerCountry">
          <span>{t(FIELD_LABELS.country)}</span>
          {context.loggedInAsAdmin ? (
            <Select
              options={COUNTRIES}
              onChange={(selectedOption) => setState(setData, 'customerCountry', selectedOption.value)}
              value={getOptionByValue(COUNTRIES, data.customerCountry)}
              styles={selectStyles}
              placeholder=""
              noOptionsMessage={() => t('noResults')}
            />
          ) : (
            <input
              type="text"
              value={getOptionByValue(COUNTRIES, data.customerCountry).label}
              disabled={!context.loggedInAsAdmin}
            />
          )}
        </Label>
      </WizardForm>
      {context.loggedInAsAdmin && (
        <Modalfooter type="settings">
          <ButtonPrimary
            ref={submitButtonRef}
            disabled={nameEmpty}
            content="save"
            onClick={() =>
              saveCustomerData({
                data: data,
                context: context
              })
            }
          />
        </Modalfooter>
      )}
    </ScrollContainer>
  )

  return content
}

export default CustomerSettingsComponent

const saveCustomerData = async ({ data, context }) => {
  const payload = {
    customerName: data.customerName.trim(),
    customerCity: data.customerCity.trim(),
    customerCountry: data.customerCountry.trim(),
    customerStreet: data.customerStreet.trim(),
    customerZipCode: data.customerZipCode.trim()
  }

  const responseData = await fetchData(payload, API_DATA.customers.endPointEdit, context, 'dataChangeFailed')

  try {
    setState(context.setCompleteDataSet, 'customer', new Customer(responseData.response.customer))
    setStatusBar({
      controller: context.statusBarController,
      type: STATUS_BAR_TYPES.success,
      text: 'dataSuccessfullyChanged',
      setVisible: true
    })
  } catch (e) {
    console.error(e)
  }
}
