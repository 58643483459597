import { CUSTOMER_VISIBILITY_STATUS, DEFAULT_LOGO_PATH } from '../utils/constants/constants'
import { createDate } from '../utils/helper/dateTimeHelper'

const PERSEO_PRIMARY_COLOR = '#5d5169'
const DEFAULT_FOOTER_BG_COLOR = '#f3f5f6'
const DEFAULT_FOOTER_TEXT_COLOR = '#686e73'
const DEFAULT_LOGO_WIDTH = 120

export class PtInvitationTemplate {
  constructor({ ...props }) {
    this.entity = 'PtInvitationTemplate'

    const template = props.templateData || props.mailData || {}

    this.publicKey = props.publicKey || 'reminder_template'
    this.name = props.title || ''
    this.footerBgColor = template.footerBgColor || DEFAULT_FOOTER_BG_COLOR
    this.footerCenter = template.footerCenter || ''
    this.footerLeft = template.footerLeft || ''
    this.footerRight = template.footerRight || ''
    this.footerTextColor = template.footerTextColor || DEFAULT_FOOTER_TEXT_COLOR
    this.headerColor = template.headerColor || PERSEO_PRIMARY_COLOR
    this.logoPath = template.logoPath || DEFAULT_LOGO_PATH
    this.logoWidthPx = template.logoWidthPx || DEFAULT_LOGO_WIDTH
    this.salutationType = template.salutationType || ''
    this.subject = template.subject || ''
    this.topText = addSpacer(template.topText || '')
    this.bottomText = addSpacer(template.bottomText || '')
    this.created = createDate(props.created)
    this.createdBy = props.createdBy || ''
    this.disabled = props.disabled || false
    this.visibility = props.visibility || CUSTOMER_VISIBILITY_STATUS.visible
  }
}

const addSpacer = (string) => string.replace(/\n\n$/, '').replace(/\n\n/g, '<div style="margin-bottom: 1em;"></div>')
