import styled from 'styled-components'
import TestInfoBlockForEmail from './TestInfoBlockForEmail'
import SalutationSelection, { getSalutation } from './SalutationSelection'
import useTranslate from '../../../utils/hooks/useTranslate'
import { AccessDataPlaceholder } from '../subPages/EmailTemplatePage'
import { Editor, EditorProvider } from 'react-simple-wysiwyg'
import { useState } from 'react'
import { useContext } from 'react'
import { AppContext } from '../../../utils/context/AppContext'
import { toggleMediaLibrary } from './MediaLibrary'
import { stripAllTags } from '../../../utils/helper/Helper'
import { DEFAULT_LOGO_ASPECT_RATIO } from '../../../utils/constants/constants'

const EmailHtmlEditor = ({ template, data, updateFn, editMode }) => {
  const t = useTranslate()
  const [imageLoaded, setImageLoaded] = useState(false)
  const context = useContext(AppContext)

  const logo = context.completeDataSet.customersFiles.find((file) => file.path === template.logoPath)
  const logoAspectRatio = logo?.aspectRatio ?? DEFAULT_LOGO_ASPECT_RATIO
  const placeholderHeight = template.logoWidthPx / logoAspectRatio

  return (
    <OuterContainer className={`email-html-editor ${editMode ? 'edit-mode' : ''}`}>
      <EditorProvider>
        <Bar color={template.headerColor} />
        <HeaderContainer>
          <LogoContainer
            editMode={editMode}
            onClick={() => editMode && toggleMediaLibrary(context, template, updateFn)}>
            {!imageLoaded && <div className="placeholder" style={{ height: `${placeholderHeight}px` }} />}
            <img
              src={template.logoPath}
              style={{ display: imageLoaded ? 'block' : 'none', width: `${template.logoWidthPx}px` }}
              onLoad={() => setImageLoaded(true)}
            />
          </LogoContainer>
        </HeaderContainer>
        <InnerContainer>
          <SubjectInput className={stripAllTags(template.subject).length === 0 ? 'show-error' : ''}>
            <Editor
              value={template.subject}
              onChange={(e) => updateFn('subject', e.target.value)}
              disabled={!editMode}
              className={template.subject.length === 0 ? 'has-error' : ''}
            />
          </SubjectInput>
          {editMode ? (
            <SalutationSelection
              {...{ template, updateFn }}
              style={{ marginBottom: 'var(--space-3)', maxWidth: '260px' }}
            />
          ) : (
            <SalutationDisplay {...{ data, template }} />
          )}
          <Editor value={template.topText} onChange={(e) => updateFn('topText', e.target.value)} disabled={!editMode} />

          {editMode ? (
            <AccessDataPlaceholder style={{ margin: 'var(--space-5) 0' }}>
              {t('accessDataPlaceholder')}
            </AccessDataPlaceholder>
          ) : (
            <TestInfoBlockForEmail {...{ data }} />
          )}
          <Editor
            value={template.bottomText}
            onChange={(e) => updateFn('bottomText', e.target.value)}
            disabled={!editMode}
          />
        </InnerContainer>
        <Footer bgColor={template.footerBgColor} textColor={template.footerTextColor}>
          <FooterContent>
            <Editor
              value={template.footerLeft}
              onChange={(e) => updateFn('footerLeft', e.target.value)}
              disabled={!editMode}
            />
            <Editor
              value={template.footerCenter}
              onChange={(e) => updateFn('footerCenter', e.target.value)}
              disabled={!editMode}
            />
            <Editor
              value={template.footerRight}
              onChange={(e) => updateFn('footerRight', e.target.value)}
              disabled={!editMode}
            />
          </FooterContent>
        </Footer>
      </EditorProvider>
    </OuterContainer>
  )
}

export default EmailHtmlEditor

const SalutationDisplay = ({ data, template }) => {
  const t = useTranslate()

  const previewParticipant =
    data?.previewParticipant || data?.participants?.find((p) => p.pNr === data.previewParticipantNr)

  if (!previewParticipant) {
    return <></>
  }

  const salutation = getSalutation(previewParticipant, template.salutationType, t)

  return <p style={{ padding: '6px 3px 0 3px', marginBottom: '14px' }}>{salutation}</p>
}

const SubjectInput = styled.div`
  font-size: var(--fs-7) !important;
  margin-bottom: var(--space-7);
  width: 100%;
  background-color: transparent !important;
  border: 1px solid transparent !important;
  color: var(--text-color-primary);
  * {
    line-height: var(--lh-4) !important;
  }
`

const HeaderContainer = styled.div`
  padding: var(--space-6);
`

const LogoContainer = styled.button`
  background-color: transparent;
  cursor: ${(props) => (props.editMode ? 'pointer' : '')};
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
  padding: 2px;
  width: fit-content;
  border-radius: var(--bdr-2);
  &:hover {
    border-color: ${(props) => (props.editMode ? 'var(--border-grey)' : '')};
  }
`

const Footer = styled.div`
  background-color: ${(props) => props.bgColor};
  div {
    color: ${(props) => props.textColor};
  }
`

const FooterContent = styled.div`
  padding: var(--space-6);
  display: grid;
  gap: var(--space-3);
  grid-template-columns: 1fr 1fr 1fr;
  font-size: var(--fs-2);
  color: var(--text-color-secondary);
  p {
    color: inherit;
    font-size: inherit !important;
    margin: 0 !important;
  }
`

const InnerContainer = styled.div`
  padding: var(--space-6);
`

const OuterContainer = styled.div`
  --text-color-primary: var(--grey-900);
  --text-color-secondary: #a1a6aa;
  --box-background: var(--grey-150);
  background-color: #fff;
  border-radius: var(--bdr-3);
  display: grid;
  overflow: hidden;
  box-shadow: var(--sh-dropdown);
  button:focus {
    border: 1px solid var(--color-focus-outline);
    box-shadow: none;
  }
  p {
    margin: 1em 0;
    font-size: var(--fs-3);
    &:first-child {
      margin-top: 0 !important;
    }
    &:last-child {
      margin-bottom: 0 !important;
    }
  }
  * {
    line-height: var(--lh-3);
  }
  li {
    color: var(--text-color-primary);
  }
  .rsw-editor {
    min-height: unset;
    border: none;
    padding: 1px;
    margin: -1px;
  }
  .rsw-ce {
    border: 1px solid transparent;
    border-radius: var(--bdr-2);
    padding: 2px;
  }
  &.edit-mode .rsw-ce {
    &:hover {
      border: 1px solid var(--border-grey);
    }
    &:focus {
      border: 1px solid var(--color-focus-outline);
      box-shadow: none;
    }
  }
`

const Bar = styled.div`
  height: 20px;
  background-color: ${(props) => props.color};
`
