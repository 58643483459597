import Select from 'react-select'
import {
  WizardForm,
  OptionDescription,
  Label,
  selectStyles,
  OptionEntry,
  ErrorMessage,
  FormSection,
  SectionTitle
} from '../../../utils/elements/miscElements'
import {
  setState,
  getOptionByValue,
  isGermanZipCode,
  isOnlyNumbers,
  getCityFromZipCode
} from '../../../utils/helper/Helper'
import { FIELD_LABELS, SELECT_OPTIONS, COUNTRIES, FORM_ERRORS } from '../../../utils/constants/constants'
import { useEffect } from 'react'
import useTranslate from '../../../utils/hooks/useTranslate'
import { validateBillingData } from '../shopUtils'

const BillingDataForm = ({ showInModal, formData, setFormData }) => {
  const t = useTranslate()

  const { emptyFields, mailValid, ccMailValid } = validateBillingData(formData)
  const visibleErrors = formData.visibleErrors
  const setVisibleErrors = (errors) => setState(setFormData, 'visibleErrors', errors)

  useEffect(() => {
    if (isGermanZipCode(formData.billingZipCode)) {
      getCityFromZipCode(formData.billingZipCode).then((city) => {
        if (city) {
          setFormData((prevData) => ({
            ...prevData,
            billingCity: city,
            billingCountry: 'DE'
          }))
          removeErrors()
        }
      })
    }
  }, [formData.billingZipCode])

  const removeErrors = () => {
    const filteredErrors = visibleErrors.filter((field) => field !== 'billingCity' && field !== 'billingCountry')
    setVisibleErrors(filteredErrors)
  }

  const handleInput = (value, fieldName) => {
    const filteredErrors = visibleErrors.filter((field) => field !== fieldName)
    setFormData((prevData) => ({
      ...prevData,
      [fieldName]: value,
      visibleErrors: filteredErrors
    }))
  }

  const handleErrorVisibility = (fieldName) => {
    let tmp = [...visibleErrors]

    if (fieldName === 'billingEmail' && !mailValid) {
      tmp.push('billingEmail')
      setVisibleErrors(tmp)
      return
    }

    if (fieldName === 'billingEmailCc' && formData?.billingEmailCc?.length > 0 && !ccMailValid) {
      tmp.push('billingEmailCc')
      setVisibleErrors(tmp)
      return
    }

    if (emptyFields.includes(fieldName) && fieldName !== 'billingEmailCc') {
      tmp.push(fieldName)
      setVisibleErrors(tmp)
      return
    }
  }

  return (
    <WizardForm style={{ gap: 'var(--space-8)' }} autoComplete="off">
      <FormSection>
        <SectionTitle title="address" />
        <OptionEntry isBillingForm hasHint={!showInModal}>
          <Label>
            <span>
              {t('company')} / {t('authority')} *
            </span>
            <input
              type="text"
              value={formData?.billingCustomer || ''}
              onChange={(e) => handleInput(e.target.value, 'billingCustomer')}
              className={visibleErrors.includes('billingCustomer') ? 'has-error' : ''}
              onBlur={() => handleErrorVisibility('billingCustomer')}
            />
            <ErrorMessage visible={visibleErrors.includes('billingCustomer')}>{t(FORM_ERRORS.fieldEmpty)}</ErrorMessage>
          </Label>
        </OptionEntry>
        <OptionEntry isBillingForm hasHint={!showInModal}>
          <Label>
            <span>{t(FIELD_LABELS.addressAddition)}</span>
            <input
              type="text"
              value={formData?.billingAddressAddition || ''}
              onChange={(e) => handleInput(e.target.value, 'billingAddressAddition')}
            />
          </Label>
        </OptionEntry>
        <OptionEntry isBillingForm hasHint={!showInModal}>
          <Label>
            <span>{t(FIELD_LABELS.street)} *</span>
            <input
              type="text"
              value={formData?.billingStreet || ''}
              onChange={(e) => handleInput(e.target.value, 'billingStreet')}
              className={visibleErrors.includes('billingStreet') ? 'has-error' : ''}
              onBlur={() => handleErrorVisibility('billingStreet')}
            />
            <ErrorMessage visible={visibleErrors.includes('billingStreet')}>{t(FORM_ERRORS.fieldEmpty)}</ErrorMessage>
          </Label>
        </OptionEntry>
        <OptionEntry isBillingForm hasHint={!showInModal}>
          <div className="zipcode-city-wrapper">
            <Label>
              <span>{t(FIELD_LABELS.zipCode)} *</span>
              <input
                type="text"
                value={formData?.billingZipCode || ''}
                onChange={(e) => {
                  if (isOnlyNumbers(e.target.value)) handleInput(e.target.value, 'billingZipCode')
                }}
                className={visibleErrors.includes('billingZipCode') ? 'has-error' : ''}
                onBlur={() => handleErrorVisibility('billingZipCode')}
              />
              <ErrorMessage visible={visibleErrors.includes('billingZipCode')}>
                {t(FORM_ERRORS.fieldEmpty)}
              </ErrorMessage>
            </Label>
            <Label>
              <span>{t(FIELD_LABELS.city)} *</span>
              <input
                type="text"
                value={formData?.billingCity || ''}
                onChange={(e) => handleInput(e.target.value, 'billingCity')}
                className={visibleErrors.includes('billingCity') ? 'has-error' : ''}
                onBlur={() => handleErrorVisibility('billingCity')}
              />
              <ErrorMessage visible={visibleErrors.includes('billingCity')}>{t(FORM_ERRORS.fieldEmpty)}</ErrorMessage>
            </Label>
          </div>
        </OptionEntry>
        <OptionEntry isBillingForm hasHint={!showInModal}>
          <Label>
            <span>{t(FIELD_LABELS.country)} *</span>
            <Select
              options={COUNTRIES}
              onChange={(selectedOption) => handleInput(selectedOption.value, 'billingCountry')}
              value={getOptionByValue(COUNTRIES, formData?.billingCountry || '')}
              styles={selectStyles}
              placeholder=""
              className={visibleErrors.includes('billingCountry') ? 'has-error' : ''}
              onBlur={() => handleErrorVisibility('billingCountry')}
              noOptionsMessage={() => t('noResults')}
            />
            <ErrorMessage visible={visibleErrors.includes('billingCountry')}>{t(FORM_ERRORS.fieldEmpty)}</ErrorMessage>
          </Label>
        </OptionEntry>
        <OptionEntry isBillingForm hasHint={!showInModal}>
          <Label isBillingForm fullWidth={!showInModal}>
            <span>{t(FIELD_LABELS.email)} *</span>
          </Label>
          <div>
            <input
              style={{ width: '100%' }}
              type="email"
              value={formData?.billingEmail || ''}
              onChange={(e) => handleInput(e.target.value, 'billingEmail')}
              className={visibleErrors.includes('billingEmail') ? 'has-error' : ''}
              onBlur={() => handleErrorVisibility('billingEmail')}
            />
            <ErrorMessage visible={visibleErrors.includes('billingEmail') && emptyFields.includes('billingEmail')}>
              {t(FORM_ERRORS.fieldEmpty)}
            </ErrorMessage>
            <ErrorMessage visible={visibleErrors.includes('billingEmail') && !mailValid}>
              {t(FORM_ERRORS.emailInvalid)}
            </ErrorMessage>
          </div>

          <OptionDescription showInModal={showInModal} isBillingForm description="emailConfirmationAndInvoice" />
        </OptionEntry>
        <OptionEntry isBillingForm hasHint={!showInModal}>
          <Label isBillingForm fullWidth={!showInModal}>
            <span>CC {t(FIELD_LABELS.email)} (optional)</span>
          </Label>
          <div>
            <input
              style={{ width: '100%' }}
              type="email"
              value={formData?.billingEmailCc || ''}
              onChange={(e) => handleInput(e.target.value, 'billingEmailCc')}
              className={visibleErrors.includes('billingEmailCc') ? 'has-error' : ''}
              onBlur={() => handleErrorVisibility('billingEmailCc')}
            />
            <ErrorMessage visible={visibleErrors.includes('billingEmailCc') && !ccMailValid}>
              {t(FORM_ERRORS.emailInvalid)}
            </ErrorMessage>
          </div>
        </OptionEntry>
      </FormSection>
      <FormSection>
        <SectionTitle isOptional title="contactPerson" />
        <OptionEntry isBillingForm hasHint={!showInModal}>
          <Label style={{ width: '100px' }}>
            <span>{t(FIELD_LABELS.salutation)}</span>
            <Select
              options={t(SELECT_OPTIONS.contactSalutations)}
              onChange={(selectedOption) => setState(setFormData, 'billingSalutation', selectedOption.value)}
              value={getOptionByValue(t(SELECT_OPTIONS.contactSalutations), formData?.billingSalutation || '')}
              styles={selectStyles}
              placeholder=""
              isSearchable={false}
            />
          </Label>
        </OptionEntry>
        <OptionEntry isBillingForm hasHint={!showInModal}>
          <Label>
            <span>{t(FIELD_LABELS.firstName)}</span>
            <input
              type="text"
              value={formData?.billingFirstName || ''}
              onChange={(e) => setState(setFormData, 'billingFirstName', e.target.value)}
            />
          </Label>
        </OptionEntry>
        <OptionEntry isBillingForm hasHint={!showInModal}>
          <Label>
            <span>{t(FIELD_LABELS.lastName)}</span>
            <input
              type="text"
              value={formData?.billingLastName || ''}
              onChange={(e) => setState(setFormData, 'billingLastName', e.target.value)}
            />
          </Label>
        </OptionEntry>
      </FormSection>

      <FormSection>
        <SectionTitle isOptional title="additionalFields" />
        <OptionEntry isBillingForm hasHint={!showInModal}>
          <div style={{ display: 'grid', gap: showInModal ? 'var(--space-5)' : 'var(--space-6)' }}>
            <div className="billing-custom-fields-wrapper">
              <Label>
                <span>{t('field')} 1 Name</span>
                <input
                  type="text"
                  value={formData?.billingCustomField1Label || ''}
                  onChange={(e) => setState(setFormData, 'billingCustomField1Label', e.target.value)}
                />
              </Label>
              <Label>
                <span>
                  {t('field')} 1 {t('content')}
                </span>
                <input
                  type="text"
                  value={formData?.billingCustomField1Content || ''}
                  onChange={(e) => setState(setFormData, 'billingCustomField1Content', e.target.value)}
                />
              </Label>
            </div>
            <div className="billing-custom-fields-wrapper">
              <Label>
                <span>{t('field')} 2 Name</span>
                <input
                  type="text"
                  value={formData?.billingCustomField2Label || ''}
                  onChange={(e) => setState(setFormData, 'billingCustomField2Label', e.target.value)}
                />
              </Label>
              <Label>
                <span>
                  {t('field')} 2 {t('content')}
                </span>
                <input
                  type="text"
                  value={formData?.billingCustomField2Content || ''}
                  onChange={(e) => setState(setFormData, 'billingCustomField2Content', e.target.value)}
                />
              </Label>
            </div>
          </div>
          <OptionDescription
            style={{ marginTop: showInModal ? '' : 'var(--space-5)' }}
            showInModal={showInModal}
            isBillingForm
            description="additionalInvoiceFields"
          />
        </OptionEntry>
      </FormSection>
    </WizardForm>
  )
}
export default BillingDataForm
