import styled from 'styled-components'
import { EMAIL_FROM_ADDRESS, EMAIL_FROM_NAME } from '../../../utils/constants/constants'
import useTranslate from '../../../utils/hooks/useTranslate'
import { useContext } from 'react'
import { AppContext } from '../../../utils/context/AppContext'

const EmailHeader = ({ subject, mailAddress }) => {
  const { language } = useContext(AppContext)
  const t = useTranslate()
  return (
    <Header>
      <Entry>
        <span>{t('sender')}:</span>
        <span>
          {EMAIL_FROM_NAME[language]} {'<'}
          {EMAIL_FROM_ADDRESS}
          {'>'}
        </span>
      </Entry>
      <Entry>
        <span>{t('to')}:</span> <span>{mailAddress}</span>
      </Entry>
      <Entry style={{ fontWeight: 600 }}>
        <span>{t('subject')}:</span> <span>{subject}</span>
      </Entry>
    </Header>
  )
}

export default EmailHeader

const Header = styled.div`
  display: grid;
  gap: var(--space-3);
  margin-bottom: var(--space-5);
  font-size: var(--fs-3);
`

const Entry = styled.div`
  display: grid;
  grid-template-columns: 80px 1fr;
`
