import Dropzone from 'react-dropzone'
import styled from 'styled-components'
import Papa from 'papaparse'
import { setState } from '../../../utils/helper/Helper'
import useTranslate from '../../../utils/hooks/useTranslate'
import { CSV_FILE_TYPES, EXCEL_FILE_TYPES } from '../../../utils/constants/constants'

const UploadDropZone = ({ data, setData }) => {
  const t = useTranslate()
  const parseFile = (file) => {
    if (EXCEL_FILE_TYPES.includes(file.type)) {
      parseExcelFile(file)
    } else if (CSV_FILE_TYPES.includes(file.type)) {
      parseCsvFile(file)
    }
  }

  const parseExcelFile = async (file) => {
    const XLSX = await import('xlsx')
    const reader = new FileReader()
    reader.onload = (evt) => {
      const bstr = evt.target.result
      const wb = XLSX.read(bstr, { type: 'binary' })
      const wsname = wb.SheetNames[0]
      const ws = wb.Sheets[wsname]
      const rawData = XLSX.utils.sheet_to_csv(ws, { header: 1 })

      const lines = rawData.trim().split('\n')
      const headers = lines[0].split(',')
      const dataRows = lines.slice(1)
      const bodyData = dataRows.map((row) => {
        const values = row.split(',')
        return headers.reduce((obj, header, index) => {
          obj[header] = values[index]
          return obj
        }, {})
      })

      setState(setData, 'parsedCsvData', removeEmptyLines(bodyData))
      setState(setData, 'uploadedHeaders', headers)
      setState(setData, 'participantCount', bodyData.length)
    }
    reader.readAsBinaryString(file)
  }

  const removeEmptyLines = (data) => data.filter((line) => Object.values(line).some((val) => val !== ''))

  const parseCsvFile = (file) => {
    Papa.parse(file, {
      header: true,
      skipEmptyLines: true,
      complete: (results) => {
        setState(setData, 'parsedCsvData', results.data)
        setState(setData, 'uploadedHeaders', results.meta.fields)
        setState(setData, 'participantCount', results.data.length)
      }
    })
  }

  const handleFiles = (acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      const reader = new FileReader()
      reader.onabort = () => console.log('file reading was aborted')
      reader.onerror = () => console.error('file reading has failed')
      reader.onload = () => {
        setState(setData, 'fileName', file.name)
        parseFile(file)
      }
      reader.readAsArrayBuffer(file)
    })
  }

  const acceptedFileTypes = [...EXCEL_FILE_TYPES, ...CSV_FILE_TYPES].join(',')

  return (
    <Dropzone accept={acceptedFileTypes} onDrop={(acceptedFiles) => handleFiles(acceptedFiles)}>
      {({ getRootProps, getInputProps, isDragAccept, isFocused, isDragReject }) => (
        <section>
          <DropzoneContainer {...getRootProps({ isFocused, isDragAccept, isDragReject })}>
            <input {...getInputProps()} />
            <p style={{ margin: 'var(--space-4) 0' }}>{t('dragOrClickToUpload')}</p>
            {data.fileName && <FileName>{data.fileName}</FileName>}
          </DropzoneContainer>
        </section>
      )}
    </Dropzone>
  )
}
export default UploadDropZone

const FileName = styled.span`
  font-weight: 600;
  font-size: var(--fs-4);
  color: var(--text-color-secondary);
  margin-bottom: var(--space-4);
`

const DropzoneContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: var(--space-6);
  border-width: 2px;
  border-radius: var(--bdr-1);
  border-color: ${(props) => getDropzoneBorderColor(props)};
  border-style: dashed;
  background-color: var(--main-bg-color);
  color: var(--text-color-secondary);
  outline: none;
  transition: border var(--hover-duration), background-color var(--hover-duration);
  text-align: center;
  cursor: pointer;
  &:hover {
    background-color: var(--dropzone-hover-bg-color);
    transition: background-color var(--hover-duration);
  }
`
export const getDropzoneBorderColor = (props) => {
  if (props.isDragAccept) {
    return 'var(--green-500)'
  }
  if (props.isDragReject) {
    return 'var(--input-error-border-color)'
  }
  return 'var(--border-grey)'
}
