import PageGroup from '../../components/pageGroup/PageGroup'
import { useContext, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { ButtonPrimary, ButtonSecondary } from '../../utils/elements/miscElements'
import { fetchData, getLoggedInContact, mailIsValid, setStatusBar, updateEntities } from '../../utils/helper/Helper'
import { AppContext } from '../../utils/context/AppContext'
import useTranslate from '../../utils/hooks/useTranslate'
import useRedirect from '../../utils/hooks/useRedirect'
import { SettingsContainer } from '../../components/settings/sharedElements/sharedElements'
import EmailPreviewPage from '../sendEmailWizard/subPages/EmailPreviewPage'
import MailSettings from '../sendEmailWizard/subPages/MailSettings'
import CheckInput from '../sendEmailWizard/subPages/CheckInput'
import { PAGES } from '../../utils/constants/pages'
import TestSelectionForReminder from './TestSelectionForReminder'
import { API_DATA, STATUS_BAR_TYPES } from '../../utils/constants/constants'
import { getDummyParticipants, getReminderNrFromUrl } from './reminderUtils'
import useTemplateUpdates from '../sendEmailWizard/useTemplateUpdates'
import EmailTemplate from '../sendEmailWizard/subPages/EmailTemplatePage'
import { getDefaultTemplates } from '../../components/editor/mailTemplateEditor/templateUtils'

const EditReminderWizard = () => {
  const t = useTranslate()
  const redirect = useRedirect()
  const [activePageId, setActivePageId] = useState(0)
  const location = useLocation()
  const context = useContext(AppContext)
  const { completeDataSet, statusBarController } = context
  const customerName = completeDataSet.customer.customerName
  const defaultTemplates = getDefaultTemplates(completeDataSet, t)
  const contact = getLoggedInContact(completeDataSet)
  const reminderNr = getReminderNrFromUrl(location)

  const DEFAULT_REMINDER_DAYS = 3
  const DEFAULT_TRIGGER_TYPE = 'before_valid_until'
  const DEFAULT_REPLY_TO_NAME = customerName
  const DEFAULT_REPLY_TO_MAIL = getLoggedInContact(completeDataSet).contactEmail
  const DEFAULT_REMINDER_TITLE = t('newReminder')
  const DEFAULT_TEMPLATE = defaultTemplates.find((t) => t.publicKey === 'default_reminder')

  const reminder = completeDataSet.reminders.find((rm) => rm.reminderNr === reminderNr)

  const availableTemplates = reminder
    ? [...defaultTemplates, ...completeDataSet.ptInvitationTemplates, reminder.template]
    : [...defaultTemplates, ...completeDataSet.ptInvitationTemplates]

  const [data, setData] = useState({
    availableTemplates: availableTemplates,
    selectedTemplateKey: reminder ? 'reminder_template' : 'default_reminder',
    emailType: 'invitation',
    startTimerOnSend: true,
    saveMethod: 'add',
    newTemplateName: '',
    isDirty: false,
    reminderNr: reminderNr,
    title: reminder?.title || DEFAULT_REMINDER_TITLE,
    replyToName: reminder?.replyToName || DEFAULT_REPLY_TO_NAME,
    replyToEmail: reminder?.replyToEmail || DEFAULT_REPLY_TO_MAIL,
    contactEmail: contact.contactEmail,
    participants: getDummyParticipants(),
    assessmentUuids: reminder?.assessmentUuids || [],
    days: reminder?.days || DEFAULT_REMINDER_DAYS,
    triggerType: reminder?.triggerType || DEFAULT_TRIGGER_TYPE,
    active: reminder?.active || false,
    showTemplateManager: reminderNr ? false : true,
    isReminder: true,
    formErrorsVisible: false,
    templateName: reminder ? null : DEFAULT_TEMPLATE.name,
    templateKey: reminder ? null : DEFAULT_TEMPLATE.publicKey,
    previewData: getDummyPreviewData(),
    previewParticipantNr: getDummyParticipants()[0].pNr,
    selectedRowIds: {}
  })

  useTemplateUpdates(setData, defaultTemplates, reminder)

  const template = data.availableTemplates.find((t) => t.publicKey === data.selectedTemplateKey)
  const subjectEmpty = template.subject.length === 0
  const emailIsValid = mailIsValid(data.replyToEmail) || data.replyToEmail === ''

  const saveReminder = async () => {
    const payload = {
      templateData: data.availableTemplates.find((t) => t.publicKey === data.selectedTemplateKey),
      title: data.title,
      replyToName: data.replyToName,
      replyToEmail: data.replyToEmail,
      reminderNr: data.reminderNr,
      assessmentUuids: data.assessmentUuids,
      days: data.days,
      triggerType: data.triggerType,
      active: data.active
    }
    const responseData = await fetchData(payload, API_DATA.reminders.endPointEdit, context)

    try {
      if (responseData.response.status === 1) {
        updateEntities(responseData.response.data, context)
        setStatusBar({
          controller: statusBarController,
          type: STATUS_BAR_TYPES.success,
          text: 'reminderSavedSuccessfully',
          setVisible: true
        })
      }
    } catch (e) {
      console.error(e)
    }
  }

  const pages = {
    templates: {
      content: <EmailTemplate {...{ data, setData }} />,
      title: t('subjectAndContent'),
      buttonSecondary: (
        <ButtonSecondary
          content="cancel"
          onClick={() => redirect(PAGES.emailSettings, [{ id: 'tab', value: 'automaticReminders' }])}
        />
      ),
      buttonPrimary: (
        <ButtonPrimary disabled={subjectEmpty} content="next" onClick={() => setActivePageId(activePageId + 1)} />
      ),
      largeButtons: true
    },
    preview: {
      content: <EmailPreviewPage {...{ data, setData }} />,
      title: t('preview'),
      buttonSecondary: <ButtonSecondary content="back" onClick={() => setActivePageId(activePageId - 1)} />,
      buttonPrimary: <ButtonPrimary content="next" onClick={() => setActivePageId(activePageId + 1)} />,
      largeButtons: true
    },
    mailSettings: {
      content: <MailSettings {...{ data, setData, emailIsValid }} />,
      title: t('settings'),
      buttonSecondary: <ButtonSecondary content="back" onClick={() => setActivePageId(activePageId - 1)} />,
      buttonPrimary: (
        <ButtonPrimary
          disabled={!emailIsValid && data.formErrorsVisible}
          content="next"
          onClick={() => setActivePageId(activePageId + 1)}
        />
      ),
      largeButtons: true
    },
    tests: {
      content: <TestSelectionForReminder selectedRowIds={data.selectedRowIds} setData={setData} />,
      title: 'Tests',
      buttonSecondary: <ButtonSecondary content="back" onClick={() => setActivePageId(activePageId - 1)} />,
      buttonPrimary: (
        <ButtonPrimary
          disabled={!data.assessmentUuids.length}
          content="next"
          onClick={() => setActivePageId(activePageId + 1)}
        />
      ),
      largeButtons: true
    },
    checkInput: {
      content: <CheckInput {...{ data }} />,
      title: t('checkInputs'),
      largeButtons: true,
      buttonSecondary: <ButtonSecondary content="back" onClick={() => setActivePageId(activePageId - 1)} />,
      buttonPrimary: (
        <ButtonPrimary
          content={t('save')}
          onClick={async () => {
            await saveReminder()
            redirect(PAGES.emailSettings, [{ id: 'tab', value: 'automaticReminders' }])
          }}
        />
      )
    }
  }

  return (
    <SettingsContainer containsPageGroup>
      <PageGroup
        pages={[pages.templates, pages.preview, pages.mailSettings, pages.tests, pages.checkInput]}
        activePageId={activePageId}
        isInSettings
      />
    </SettingsContainer>
  )
}

export default EditReminderWizard

const getDummyPreviewData = () => {
  const DUMMY_TITLE = 'Mustertest'
  const DUMMY_PT_NUMBER = 'PAS-12345-67890'
  const DUMMY_TOKEN = '1234567890'

  return [
    {
      title: DUMMY_TITLE,
      ptNumber: DUMMY_PT_NUMBER,
      token: DUMMY_TOKEN,
      requiredMaterials: []
    }
  ]
}
