import PageGroup from '../../components/pageGroup/PageGroup'
import CheckInput from './subPages/CheckInput'
import SendEmailSuccessPage from './subPages/SendEmailSuccessPage'
import { useContext, useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { ButtonPrimary, ButtonSecondary } from '../../utils/elements/miscElements'
import { getLoggedInContact, isValidHex, mailIsValid, setState } from '../../utils/helper/Helper'
import { PAGES } from '../../utils/constants/pages'
import { AppContext } from '../../utils/context/AppContext'
import { getParticipantsFromUrlParams, handleEmailModal } from './sendEmailUtils'
import useTranslate from '../../utils/hooks/useTranslate'
import MailSettings from './subPages/MailSettings'
import useTemplateUpdates from './useTemplateUpdates'
import EmailPreviewPage from './subPages/EmailPreviewPage'
import useRedirect from '../../utils/hooks/useRedirect'
import ParticipantSelectForEmails from './subPages/ParticipantSelectForEmails'
import EmailTemplate from './subPages/EmailTemplatePage'
import { getDefaultTemplates } from '../../components/editor/mailTemplateEditor/templateUtils'

const SendEmailWizard = () => {
  const t = useTranslate()
  const history = useHistory()
  const redirect = useRedirect()
  const [activePageId, setActivePageId] = useState(0)
  const location = useLocation()
  const context = useContext(AppContext)
  const { completeDataSet } = context
  const customerName = completeDataSet.customer.customerName
  const initialData = getParticipantsFromUrlParams(location, completeDataSet.participants)
  const defaultTemplates = getDefaultTemplates(completeDataSet, t)
  const contact = getLoggedInContact(completeDataSet)
  const initialTemplateKey = defaultTemplates[0].publicKey
  const availableTemplates = [...defaultTemplates, ...completeDataSet.ptInvitationTemplates]

  const [data, setData] = useState({
    availableTemplates: availableTemplates,
    selectedTemplateKey: initialTemplateKey,
    participants: initialData,
    emailType: 'invitation',
    startTimerOnSend: true,
    previewParticipantNr: initialData?.[0]?.pNr,
    saveMethod: 'add',
    newTemplateName: '',
    isDirty: false,
    replyToName: customerName,
    replyToEmail: getLoggedInContact(completeDataSet).contactEmail,
    formErrorsVisible: false,
    contactEmail: contact.contactEmail,
    showTemplateManager: true,
    selectedRowIds: {}
  })

  useEffect(() => {
    setState(setData, 'previewParticipantNr', data.participants[0]?.pNr)
  }, [data.participants.length])

  const [response, setReponse] = useState({})
  const [submitButtonClicked, setSubmitButtonClicked] = useState(false)

  useTemplateUpdates(setData, defaultTemplates)

  useEffect(() => {
    if (submitButtonClicked) {
      handleEmailModal(
        context,
        data,
        setActivePageId,
        activePageId,
        setReponse,
        submitButtonClicked,
        setSubmitButtonClicked,
        t
      )
    }
  }, [submitButtonClicked])

  const template = data.availableTemplates.find((t) => t.publicKey === data.selectedTemplateKey)
  const subjectEmpty = template.subject.length === 0
  const hexValues = [template.headerColor, template.footerBgColor, template.footerTextColor]
  const hexValuesInvalid = hexValues.some((hex) => !isValidHex(hex))
  const emailIsValid = mailIsValid(data.replyToEmail) || data.replyToEmail === ''
  const noParticipantsSelected = data.participants.length === 0

  const BackButton = <ButtonSecondary content="back" onClick={() => setActivePageId(activePageId - 1)} />
  const CancelButton = <ButtonSecondary content="cancel" onClick={history.goBack} />

  const pages = {
    participants: {
      content: <ParticipantSelectForEmails selectedRowIds={data.selectedRowIds} setData={setData} />,
      title: t('participants'),
      buttonSecondary: CancelButton,
      buttonPrimary: (
        <ButtonPrimary
          disabled={noParticipantsSelected}
          content="next"
          onClick={() => setActivePageId(activePageId + 1)}
        />
      ),
      largeButtons: true
    },
    templates: {
      content: <EmailTemplate {...{ data, setData }} />,
      title: t('subjectAndContent'),
      buttonSecondary: initialData.length > 0 ? CancelButton : BackButton,
      buttonPrimary: (
        <ButtonPrimary
          disabled={subjectEmpty || hexValuesInvalid}
          content="next"
          onClick={() => setActivePageId(activePageId + 1)}
        />
      ),
      largeButtons: true
    },
    preview: {
      content: <EmailPreviewPage {...{ data, setData }} />,
      title: t('preview'),
      buttonSecondary: BackButton,
      buttonPrimary: <ButtonPrimary content="next" onClick={() => setActivePageId(activePageId + 1)} />,
      largeButtons: true
    },
    mailSettings: {
      content: <MailSettings {...{ data, setData, emailIsValid }} />,
      title: t('settings'),
      buttonSecondary: BackButton,
      buttonPrimary: (
        <ButtonPrimary
          disabled={!emailIsValid && data.formErrorsVisible}
          content="next"
          onClick={() => setActivePageId(activePageId + 1)}
        />
      ),
      largeButtons: true
    },
    checkInput: {
      content: <CheckInput {...{ data }} />,
      title: t('checkInputs'),
      largeButtons: true,
      buttonSecondary: BackButton,
      buttonPrimary: (
        <ButtonPrimary
          content={t('send') + '…'}
          onClick={() =>
            handleEmailModal(
              context,
              data,
              setActivePageId,
              activePageId,
              setReponse,
              submitButtonClicked,
              setSubmitButtonClicked,
              t
            )
          }
        />
      )
    },
    sendEmailSuccessPage: {
      content: <SendEmailSuccessPage {...{ response }} />,
      hidePageIndicator: true,
      buttonPrimary: (
        <ButtonPrimary content="goToOverview" style={{ gridColumn: 2 }} onClick={() => redirect(PAGES.dashboard)} />
      )
    }
  }

  const shortProcess = [
    pages.templates,
    pages.preview,
    pages.mailSettings,
    pages.checkInput,
    pages.sendEmailSuccessPage
  ]

  const longProcess = [pages.participants, ...shortProcess]

  return <PageGroup pages={initialData.length > 0 ? shortProcess : longProcess} activePageId={activePageId} />
}

export default SendEmailWizard
