import { createDate } from '../utils/helper/dateTimeHelper'
import { MainEntity } from './MainEntity'

export class Contact extends MainEntity {
  constructor({ ...props }) {
    super()
    this.entity = 'Contact'
    this.contactEmail = props.contactEmail
    this.contactFirstName = props.contactFirstName
    this.contactGender = props.contactGender
    this.contactIsAdmin = props.contactIsAdmin
    this.contactLastName = props.contactLastName
    this.contactFullName = `${props.contactFirstName} ${props.contactLastName}`
    this.contactPhone = props.contactPhone
    this.contactTitle = props.contactTitle
    this.contactUuid = props.contactUuid
    this.lastLogin = createDate(props.lastLogin)
    this.loggedInContact = props.loggedInContact
    this.newsletter = props.newsletter
    this.notificationStatus = props.notificationStatus
    this.visibility = props.visibility
    this.infoHtml = this.getInfoHtml()
  }
  getContactLabel() {
    if (this.contactFirstName && this.contactLastName) {
      return this.contactFirstName + ' ' + this.contactLastName
    }
    if (this.contactEmail) {
      return this.contactEmail
    }
    return ''
  }
  getInfoHtml() {
    const nameAndMailInfo = `${this.contactFullName}<br><a href="mailto:${this.contactEmail}" style="color: inherit;">${this.contactEmail}</a>`
    const phoneInfo = this.contactPhone ? `<br> ${this.contactPhone}` : ''
    return nameAndMailInfo + phoneInfo
  }
}
