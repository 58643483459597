import { useContext } from 'react'
import { SELECT_OPTIONS } from '../../../utils/constants/constants'
import { AppContext } from '../../../utils/context/AppContext'
import { getOptionByValue } from '../../../utils/helper/Helper'
import useTranslate from '../../../utils/hooks/useTranslate'

const AnonymizationNotice = () => {
  const t = useTranslate()
  const context = useContext(AppContext)
  const customer = context.completeDataSet.customer
  const timeSpan = getTimeSpan(customer)
  return <span>{t('dataRetentionInfo', t(timeSpan), customer.anonymizeTimeSpan)}</span>
}

export default AnonymizationNotice

const getTimeSpan = (customer) => {
  const timeSpan = getOptionByValue(SELECT_OPTIONS.anonymizeTimeSpans, customer.anonymizeTimeSpan)?.label
  return timeSpan
}
