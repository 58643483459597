import { FIELD_LABELS } from '../../../utils/constants/constants'
import { getPtLink } from '../../../utils/helper/Helper'
import useTranslate from '../../../utils/hooks/useTranslate'
import styled from 'styled-components'
import { getPreviewData } from '../sendEmailUtils'

const TestInfoBlockForEmail = ({ data }) => {
  const previewData = data.previewData || getPreviewData(data)

  return (
    <TestInfosUl>
      {previewData.map((previewTest, i) => (
        <li key={previewTest.token + i} style={{ listStyleType: 'none' }}>
          <TestInfoBlock {...{ previewTest }} />
        </li>
      ))}
    </TestInfosUl>
  )
}

export default TestInfoBlockForEmail

const TestInfoBlock = ({ previewTest }) => {
  const t = useTranslate()
  const materialsList = previewTest.requiredMaterials.map((entry) => t(entry)).join(', ')
  return (
    <TestInfoBlockUl>
      <InfoContainer label={t('title')} content={previewTest.title} />
      <InfoContainer label={t(FIELD_LABELS.ptNumber)} content={previewTest.ptNumber} />
      <InfoContainer label={t(FIELD_LABELS.validFrom)} content={previewTest.validFrom} />
      <InfoContainer label={t(FIELD_LABELS.validUntil)} content={previewTest.validUntil} />
      <InfoContainer label={t('requiredMaterials')} content={materialsList} />
      <InfoContainer
        label={t('testlink')}
        content={getPtLink(previewTest.token)}
        contentStyle={{ wordBreak: 'break-word', textDecoration: 'underline', color: '#52215e' }}
      />
    </TestInfoBlockUl>
  )
}

const InfoContainer = ({ label, content, contentStyle }) => (
  <>
    {content && (
      <InfoContainerLi>
        <span style={{ fontWeight: 500 }}>{label}</span>
        <span style={contentStyle}>{content}</span>
      </InfoContainerLi>
    )}
  </>
)

const TestInfosUl = styled.ul`
  padding: var(--space-3);
  display: grid;
  gap: var(--space-5);
  background-color: var(--box-background);
  border-radius: var(--bdr-2);
  margin: var(--space-5) 0;
`

const InfoContainerLi = styled.li`
  display: grid;
  grid-template-columns: 110px 1fr;
  list-style-type: none;
`

const TestInfoBlockUl = styled.ul`
  padding: 0;
  display: grid;
`
